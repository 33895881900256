














































import {
  defineComponent,
  computed,
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { useProduct, useImage } from '~/composables';
import { useDeclination } from '~/diptyqueTheme/composable';
import { Product } from '~/modules/catalog/product/types';
import { useAddToCart } from '~/helpers/cart/addToCart';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';

export default defineComponent({
  name: 'ProductPush',
  components: {
    VaimoProductCard: () => import('organisms/VaimoProductCard.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getProductList } = useProduct();
    const declinationData = computed(() => props.sectionData);
    const { getBannerData } = useDeclination(props.sectionData);
    const { addItemToCart, isInCart, loading: addLoading } = useAddToCart();
    const { getMagentoImage } = useImage();
    // TODO: setup correct types for product - <Product | null>
    const product = ref({});
    const fetchProductData = async (productSku: string) => {
      if (!productSku) {
        product.value = [];
        return;
      }

      try {
        const data = await getProductList({
          filter: {
            ['sku']: {
              in: [productSku]
            }
          },
          pageSize: 1
        });
        product.value = data?.items?.[0] as Product;
      } catch (error) {
        console.error(error);
      }
    };

    const strippedHtml = (str): string => {
      let regex = /(<([^>]+)>)/gi;

      return str.replace(regex, '');
    };

    const getProductLink = (product: Product): string => {
      return `${productGetters.getSlug(product, product?.categories?.[0])}`;
    };

    const addLoadingSKU = ref(null);
    const addToCart = (item) => {
      addLoadingSKU.value = item.product.sku;
      addItemToCart(item);
    };

    const getLoadingFlag = (product) => {
      return addLoading.value && addLoadingSKU.value === product.sku;
    };

    useFetch(async () => {
      await fetchProductData(props.sectionData.sku);
    });

    const getProductHoverImage = (product: Product): string | null => {
      if (!product || !product['hover_on_card']) {
        return null;
      }
      return product['hover_on_card'];
    };

    const getProductCapacity = (product: Product): string | null => {
      if (!product || !product['capacity']) {
        return null;
      }

      const capacity = product['capacity'];
      return capacity[0]?.label ?? null;
    };

    const getRelatedProductsQnty = (product: Product): number | null => {
      if (!product || !product['revamp_related_products']) {
        return null;
      }

      const relatedProducts = product['revamp_related_products'];
      const qty = relatedProducts.length > 1 ? relatedProducts.length - 1 : 0;

      return qty;
    };

    const getProductPLPDescription = (product: Product): string | null => {
      if (!product || !product['plp_description']) {
        return '';
      }
      return product['plp_description'];
    };

    return {
      declinationData,
      getBannerData,
      product,
      getProductLink,
      addToCart,
      getLoadingFlag,
      getMagentoImage,
      isInCart,
      strippedHtml,
      productGetters,
      customProductGetters,
      validateFraction,
      getProductHoverImage,
      getProductCapacity,
      getRelatedProductsQnty,
      getProductPLPDescription
    };
  }
});
