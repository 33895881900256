












































































































































































import type { PropType } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import { SfInput } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import VaimoHeading from '~/diptyqueTheme/components/atoms/VaimoHeading.vue';
import { useRules } from '~/diptyqueTheme/composable/useRules';
import { charsRegex, doubleQuotesRegex } from '~/diptyqueTheme/helpers/regexes';
import {useStore} from "~/composables";
import XmasForm from "~/diptyqueTheme/modules/xmas/components/XmasForm.vue";

extend('chars', {
  message: 'Only these characters',
  validate(value) {
    return {
      valid: charsRegex.test(value)
    };
  }
});

extend('doubleQuotes', {
  message: '" is a forbidden character',
  validate(value) {
    return {
      valid: doubleQuotesRegex.test(value)
    };
  }
});

type GiftMessageFormFields = {
  from: string;
  to: string;
  message: string;
};

export default defineComponent({
  name: 'VaimoGiftMessageFormHK',
  components: {
    XmasForm,
    SfInput,
    ValidationProvider,
    ValidationObserver,
    VaimoHeading,
    VaimoButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    maxLength: {
      type: Number,
      required: false,
      default: 100
    },
    form: {
      type: Object as PropType<GiftMessageFormFields>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submit'],
  setup(props) {
    const {
      app: { i18n }
    } = useContext();
    const { getRules } = useRules();
    const copyForm = ref<GiftMessageFormFields>();
    const formMessages = ref({
      message_one: '',
      message_two: '',
      message_three: ''
    });
    const { isJpStore } = useStore();

    const getFormMessage = (obj, field, msg) => {
      obj[field] = msg;
      combinedMessage();
    };

    const getMessageByStore = computed(() => {
      const messages = isJpStore.value
        ? [
            formMessages.value.message_one,
            formMessages.value.message_two,
            formMessages.value.message_three
          ]
        : [
            `${i18n.t('Message 1:')} ${formMessages.value.message_one}`,
            `${i18n.t('Message 2:')} ${formMessages.value.message_two}`,
            `${i18n.t('Message 3:')} ${formMessages.value.message_three}`
          ];

      return isJpStore.value ? messages.join('\n') : messages.join(' ');
    });

    const combinedMessage = () => {
      copyForm.value.message = formMessages.value.message_one
        ? getMessageByStore.value
        : '';
    };

    watch(
      () => props.form,
      (form) => {
        copyForm.value = { ...form };
      },
      { immediate: true, deep: true }
    );

    const isExpanded = ref(false);

    const getRemainingCharacters = (message: string) => {
      const maxChars = +props.maxLength || 0;
      const remainingChars = maxChars - message.length;
      return i18n.t('Characters remaining: {0}', {
        0: remainingChars
      });
    };

    const remainingCharacters = computed(() => ({
      message_one: getRemainingCharacters(formMessages.value.message_one),
      message_two: getRemainingCharacters(formMessages.value.message_two),
      message_three: getRemainingCharacters(formMessages.value.message_three)
    }));

    const isLoading = computed(() => props.loading);

    return {
      copyForm,
      isLoading,
      isExpanded,
      formMessages,
      getFormMessage,
      remainingCharacters,
      getRules,
      combinedMessage,
      isJpStore
    };
  }
});
