var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vaimo-subscription-modal"},[_c('SfModal',{directives:[{name:"e2e",rawName:"v-e2e",value:('subscription-modal'),expression:"'subscription-modal'"}],attrs:{"visible":_vm.isSubscriptionModalVisible},on:{"close":_vm.triggerToggleSubscriptionModal}},[_c('div',{staticClass:"vaimo-subscription-modal__wrapper"},[_c('div',{staticClass:"title"},[_vm._v("\n        "+_vm._s(_vm.$t('Subscribe to our newsletter'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"description"},[_vm._v("\n        "+_vm._s(_vm.$t(
            'Tell you the latest creations of the House in preview, send you private invitations or simply our news... Receive our newsletter.'
          ))+"\n      ")]),_vm._v(" "),(_vm.showForm)?_c('div',{staticClass:"form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var invalid = ref.invalid;
return [_c('div',{staticClass:"input",class:{ 'with-padding': !_vm.isCheckboxVisible }},[_c('ValidationProvider',{attrs:{"rules":"required|email_ex"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('SfInput',{staticClass:"center input--with-label",attrs:{"type":"email","name":"email","valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":_vm.$t('Enter your email address'),"label":_vm.$t('Enter your email address')},on:{"click":_vm.changeCheckboxVisibility,"input":function($event){_vm.status = ''}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_vm._v(" "),(_vm.status)?_c('div',{staticClass:"form-feedback"},[_c('VaimoIcon',{staticClass:"checkmark",attrs:{"icon":_vm.isStatusInvalid ? 'cross' : 'check',"label":_vm.$t('Checkmark'),"size":_vm.isStatusInvalid ? 12 : 7}}),_vm._v(" "),_c('p',{staticClass:"form-feedback-message ml-xs",class:{
              success: _vm.status === 'success',
              error: _vm.isStatusInvalid
            }},[_vm._v("\n              "+_vm._s(_vm.statusMessage)+"\n            ")])],1):_vm._e(),_vm._v(" "),(_vm.isCheckboxVisible)?_c('div',{staticClass:"checkbox"},[_c('VaimoCheckbox',{attrs:{"selected":_vm.termsAccepted},on:{"input":_vm.changeTermsAcceptedFlag},model:{value:(_vm.termsAccepted),callback:function ($$v) {_vm.termsAccepted=$$v},expression:"termsAccepted"}}),_vm._v(" "),_c('span',[_vm._v("\n            "+_vm._s(_vm.$t('By checking this box, I agree to'))+"\n            "),_c('a',{staticClass:"underline",attrs:{"href":_vm.getTermsOfServiceLinks.terms_link,"target":"_blank","aria-label":_vm.$t('General Terms and Conditions') +
                ' ' +
                _vm.$t('Opens in new tab'),"title":_vm.$t('General Terms and Conditions') +
                ' ' +
                _vm.$t('Opens in new tab'),"rel":"noreferrer nofollow"}},[_vm._v("\n              "+_vm._s(_vm.$t('General Terms and Conditions'))+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.$t('and the'))+"\n            "),_c('a',{staticClass:"underline",attrs:{"href":_vm.getTermsOfServiceLinks.policy_link,"target":"_blank","aria-label":_vm.$t('Privacy Policy') + ' ' + _vm.$t('Opens in new tab'),"title":_vm.$t('Privacy Policy') + ' ' + _vm.$t('Opens in new tab'),"rel":"noreferrer nofollow"}},[_vm._v("\n              "+_vm._s(_vm.$t('Privacy Policy'))+"\n            ")]),_vm._v("\n            "+_vm._s(_vm.isStoreDe ? 'zu.' : '.')+"\n          ")])],1):_vm._e(),_vm._v(" "),_c('VaimoButton',{attrs:{"variant":"default","disabled":!_vm.termsAccepted || invalid,"label-centered":true,"full-width":true},on:{"click":_vm.submitForm}},[_vm._v("\n            "+_vm._s(_vm.$t('Subscribe'))+"\n          ")])]}}],null,false,3254569839)})],1):_c('p',{staticClass:"mt-3md text-center"},[_vm._v("\n        "+_vm._s(_vm.$t('We will send you a link to confirm your registration.'))+"\n      ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }