
































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  useContext
} from '@nuxtjs/composition-api';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useProduct, useUiNotification } from '~/composables';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import useCart from '~/modules/checkout/composables/useCart';
import { useCartStore } from '~/modules/checkout/stores/cart';

export default defineComponent({
  name: 'Instalike',
  components: {
    LoadWhenVisible,
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoProductsGrid: () => import('organisms/VaimoProductsGrid.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    pageName: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const { app } = useContext();
    const { $gtm } = app;
    const { isDesktop } = useScreenSize();
    const { load, cart } = useCart();
    const cartStore = useCartStore();
    const declinationData = computed(() => props.sectionData);
    const { getBannerData } = useDeclination(props.sectionData);
    const contentElem = ref(null);
    const { send: sendNotification } = useUiNotification();
    const { getProductList } = useProduct();
    const productList = ref([]);
    const sidebarIsOpen = ref(false);
    const hideSidebar = () => {
      sidebarIsOpen.value = false;
    };

    const originalVisual = computed(() => {
      return isDesktop.value
        ? props.sectionData?.visualDesktop
        : props.sectionData?.visual;
    });

    const pins = computed(() => {
      updateContentElementSizes();
      return props.sectionData?.pinsCollection?.items.reduce((memo, cur) => {
        if (cur) {
          const position = calculatePinPosition(cur);
          if (position) memo.push(position);
          return memo;
        }
      }, []);
    });

    const filteredPins = computed(() => {
      const skusList = productList.value.map((product) => product.sku);

      return pins.value.filter((pin) => skusList.includes(pin.sku));
    });

    const calculatePinPosition = (pin) => {
      const focalPoint = isDesktop.value
        ? pin.pinDesktop?.focalPoint
        : pin.pinMobile.focalPoint;

      if (!focalPoint) return null;

      const width = originalVisual.value.width;
      const height = originalVisual.value.height;

      const yDoubleShift = width > height ? (1 - 9 / 16) * 100 * 2 : 0;
      const yShift = yDoubleShift / 2;
      const xDoubleShift = height > width ? (1 - 3 / 4) * 100 : 0;
      const xShift = xDoubleShift / 2;

      const left = ((100 + xDoubleShift) / width) * focalPoint.x - xShift;
      const top = ((100 + yDoubleShift) / height) * focalPoint.y - yShift;

      pin.position = {
        left: Math.round(left),
        top: Math.round(top)
      };

      return pin;
    };

    const skuList = computed(() => pins.value.map((pin) => pin.sku));

    const scrollToProduct = (sku) => {
      const id = declinationData.value?.sys?.id;
      document
        .querySelector(
          `.instalike--${id} .vaimo-product-card[data-sku="${sku}"]`
        )
        .scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
    };

    const onPinClick = (sku) => {
      if (sidebarIsOpen.value) {
        scrollToProduct(sku);
        return;
      }

      sidebarIsOpen.value = true;
      setTimeout(() => {
        scrollToProduct(sku);
      }, 350);
    };

    const contentElementSizes = reactive({
      width: 0,
      height: 0
    });

    function observeContentElHeight() {
      const resizeObserver = new ResizeObserver(function () {
        updateContentElementSizes();
      });
      resizeObserver.observe(contentElem.value);
    }

    const updateContentElementSizes = () => {
      if (!contentElem.value) return;
      contentElementSizes.width = contentElem.value?.offsetWidth;
      contentElementSizes.height = contentElem.value?.offsetHeight;
    };

    const getProductsBySku = async () => {
      const skusList = props.sectionData?.pinsCollection?.items.map(
        (pin) => pin.sku
      );
      try {
        const data = await getProductList({
          filter: {
            sku: {
              in: skusList
            }
          }
        });
        if (data?.items) {
          productList.value = data?.items.sort((item1, item2) => {
            const index1 = skusList.indexOf(item1.sku);
            const index2 = skusList.indexOf(item2.sku);
            return index1 - index2;
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    onMounted(async () => {
      observeContentElHeight();
      await getProductsBySku();
    });

    const instalikeIdClass = computed(() => {
      return 'instalike--' + declinationData.value?.sys?.id;
    });

    const getPinStyles = (pin) => {
      return {
        top: pin.position.top + '%',
        left: pin.position.left + '%'
      };
    };

    const addSidebarProductsLoading = ref(false);
    const addSidebarProductsToCart = async () => {
      if (addSidebarProductsLoading.value) return;
      addSidebarProductsLoading.value = true;
      const cartId = cart.value?.id;
      if (!cartId) {
        await load({
          realCart: true
        });
      }
      try {
        const res = await app.context.$vsf.$magento.api.addProductsToCart({
          cartId: cartStore.cart.id || cartId,
          cartItems: skuList.value.map((sku) => ({
            quantity: 1,
            sku: sku
          }))
        });

        if (res.data?.addProductsToCart?.cart?.total_quantity) {
          cartStore.$patch((state) => {
            state.cart = res.data.addProductsToCart.cart;
          });
          await load();

          sendNotification({
            id: Symbol('instalike_add'),
            message: app.i18n.t(
              'Products successfully added to your bag'
            ) as string,
            type: 'success',
            persist: false,
            title: app.i18n.t('Products added') as string,
            action_type: 'add_all_to_cart'
          });
        }

        if (res.data?.addProductsToCart?.user_errors.length) {
          // TODO: Maybe there is a good point to show toast messages to the user
          // But backend should add the SKU-info to the errors' text before to make alerts informative
          console.error('[Instalike]', res.data.addProductsToCart.user_errors);
        }

        if (res) addSidebarProductsLoading.value = false;
      } catch (e) {
        console.log('[Instalike]', e.message, e);
        addSidebarProductsLoading.value = false;
      }
    };

    const getClickPictureDetails = () => {
      if (props?.pageName === 'Home') {
        $gtm.push({
          event: 'clickHPPictures',
          sectionName: declinationData.value.heading
        });
      }
    };

    return {
      isDesktop,
      sidebarIsOpen,
      declinationData,
      getBannerData,
      productList,
      filteredPins,
      skuList,
      pins,
      contentElem,
      instalikeIdClass,
      onPinClick,
      getPinStyles,
      hideSidebar,
      addSidebarProductsToCart,
      addSidebarProductsLoading,
      getClickPictureDetails
    };
  }
});
