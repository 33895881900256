





































import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';

import { useConfig, useUiState } from '~/composables';
import { setCookie } from '~/diptyqueTheme/helpers/cookieHelper';
import { useIpData } from '~/diptyqueTheme/stores/ipData';

export default defineComponent({
  name: 'VaimoRedirectModal',
  components: {
    SfModal,
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  setup() {
    const { isRedirectModalOpen, toggleRedirectModal } = useUiState();
    const ipDataState = useIpData();
    const { app } = useContext();
    const { config } = useConfig();
    const redirectConfig = {
      FR: {
        storeText: 'France',
        redirectFrom: 'fr_eu',
        redirectTo: 'fr_fr'
      },
      GB: {
        storeText: 'UK',
        redirectFrom: 'en_eu',
        redirectTo: 'en_uk'
      }
    };

    const getButtonText = computed(() => {
      return app.i18n.t('go to our {store} website', {
        store: getStoreText.value
      });
    });

    const getTitleText = computed(() => {
      return app.i18n.t(
        'You are visiting us from another country, would you like to visit our local {store} website?',
        {
          store: getStoreText.value
        }
      );
    });

    const getStoreText = computed(() => {
      return redirectConfig[ipDataState.userCountry]?.storeText;
    });

    const expirationDate = new Date(
      +new Date() + config.value.web_cookie_cookie_lifetime * 1000
    );

    const onClose = () => {
      toggleRedirectModal();
      setCookie('store_switcher_redirect_modal', 'true', expirationDate);
    };

    const redirectToStore = () => {
      const currentURL = window.location.href;
      const from = redirectConfig[ipDataState.userCountry]?.redirectFrom;
      const to = redirectConfig[ipDataState.userCountry]?.redirectTo;
      setCookie('store_switcher_redirect_modal', 'true', expirationDate);
      if (from && to) window.location.href = currentURL.replace(from, to);
    };

    return {
      isRedirectModalOpen,
      getButtonText,
      getTitleText,
      redirectToStore,
      onClose
    };
  }
});
