import { render, staticRenderFns } from "./VaimoSectionTemplate.vue?vue&type=template&id=0c4a9891&scoped=true&"
import script from "./VaimoSectionTemplate.vue?vue&type=script&lang=js&"
export * from "./VaimoSectionTemplate.vue?vue&type=script&lang=js&"
import style0 from "./VaimoSectionTemplate.vue?vue&type=style&index=0&id=0c4a9891&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c4a9891",
  null
  
)

export default component.exports