






















































import {
  ref,
  onMounted,
  useContext, defineComponent
} from '@nuxtjs/composition-api';
import {
  SfTable,
  SfLoader,
  SfImage
} from '@storefront-ui/vue';
import { useUiNotification } from '~/composables';
import { useGmoPayment } from '~/diptyqueTheme/modules/gmo-payment/composables/useGmoPayment';

export default defineComponent({
  name: 'VaultCardList',
  components: {
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    SfImage,
    SfTable,
    SfLoader
  },
  setup() {
    const { app } = useContext();
    const { send: sendNotification, notifications } = useUiNotification();
    const { loadCustomerPaymentTokens, deletePaymentToken, vaultCards, error } = useGmoPayment();
    const deleteCardFlag = ref(false);

    onMounted(async () => {
      await loadCustomerPaymentTokens();
    })

    const deleteCard = (card) => {
      sendNotification({
        id: Symbol('delete-card-confirmation'),
        message: app.i18n.t('Are you sure you want to delete this card?') as string,
        type: 'info',
        persist: true,
        action: {
          text: app.i18n.t('OK') as string,
          onClick: async () => {
            notifications.value[0].dismiss();
            await handleDeleteCard(card.publicHash);
          }
        }
      });
    };

    const handleDeleteCard = async (hash) => {
      deleteCardFlag.value = true;
      try {
        await deletePaymentToken(hash);
        if (error.value?.deletePaymentToken) {
          sendNotification({
            icon: 'error',
            id: Symbol(`delete-card-error`),
            message: error.value?.deletePaymentToken.message,
            persist: false,
            title: app.i18n.t('Delete card error') as string,
            type: 'danger',
            area: 'top'
          })
        }
      } catch (error) {
        console.error(error);
      } finally {
        deleteCardFlag.value = false;
      }
    };

    return {
      tableHeaders: ['Type', 'Credit Card Number', 'Expiration Date'],
      vaultCards,
      deleteCardFlag,
      deleteCard
    };
  },
});
