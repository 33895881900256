var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form gift-message__wrapper"},[(_vm.title)?_c('VaimoHeading',{staticClass:"gift-message__heading",attrs:{"heading-level":"h3","heading":_vm.title,"animated-heading":false}}):_vm._e(),_vm._v(" "),_c('ValidationObserver',{staticClass:"form-container",class:{ 'form--expanded': _vm.isExpanded },scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form gift-message-form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () {
          _vm.$emit('submit', _vm.copyForm, invalid);
        })}}},[(!_vm.isJpStore)?_c('div',{staticClass:"gift-message-form__input-wrapper"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":_vm.getRules({
              en_hk: 'chars|doubleQuotes',
              zh_hk: 'chars|doubleQuotes',
              ja_jp: 'chars'
            })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('gift-message-form-from'),expression:"'gift-message-form-from'"}],staticClass:"input--with-label gift-message-form__recipient",attrs:{"name":"to","label":_vm.$t('Recipient'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":' '},model:{value:(_vm.copyForm.to),callback:function ($$v) {_vm.$set(_vm.copyForm, "to", $$v)},expression:"copyForm.to"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":_vm.getRules({
              en_hk: 'chars|doubleQuotes',
              zh_hk: 'chars|doubleQuotes',
              ja_jp: 'chars'
            }),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('gift-message-form-from'),expression:"'gift-message-form-from'"}],staticClass:"input--with-label gift-message-form__receiver",attrs:{"name":"from","label":_vm.$t('Your name'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":' '},model:{value:(_vm.copyForm.from),callback:function ($$v) {_vm.$set(_vm.copyForm, "from", $$v)},expression:"copyForm.from"}})]}}],null,true)})],1):_vm._e(),_vm._v(" "),_c('ValidationProvider',{staticClass:"mt-xs m-and-l:mt-base",attrs:{"rules":_vm.getRules({
            en_hk: 'chars|doubleQuotes',
            zh_hk: 'chars|doubleQuotes',
            ja_jp: 'chars'
          }),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('gift-message-form-message'),expression:"'gift-message-form-message'"}],staticClass:"input--with-label gift-message-form__message",attrs:{"type":"text","name":"message-one","maxlength":_vm.maxLength,"label":_vm.$t('Your message 1'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":' '},on:{"input":function($event){return _vm.getFormMessage(_vm.formMessages, 'message_one', $event)}},model:{value:(_vm.formMessages.message_one),callback:function ($$v) {_vm.$set(_vm.formMessages, "message_one", $$v)},expression:"formMessages.message_one"}}),_vm._v(" "),_c('div',{staticClass:"gift-message-form__counter"},[_c('span',[_vm._v(_vm._s(_vm.remainingCharacters.message_one))])])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":_vm.getRules({
            en_hk: 'chars|doubleQuotes',
            zh_hk: 'chars|doubleQuotes',
            ja_jp: 'chars'
          }),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('gift-message-form-message'),expression:"'gift-message-form-message'"}],staticClass:"input--with-label gift-message-form__message",attrs:{"type":"text","name":"message-two","maxlength":_vm.maxLength,"label":_vm.$t('Your message 2'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":' '},on:{"input":function($event){return _vm.getFormMessage(_vm.formMessages, 'message_two', $event)}},model:{value:(_vm.formMessages.message_two),callback:function ($$v) {_vm.$set(_vm.formMessages, "message_two", $$v)},expression:"formMessages.message_two"}}),_vm._v(" "),_c('div',{staticClass:"gift-message-form__counter"},[_c('span',[_vm._v(_vm._s(_vm.remainingCharacters.message_two))])])]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":_vm.getRules({
            en_hk: 'chars|doubleQuotes',
            zh_hk: 'chars|doubleQuotes',
            ja_jp: 'chars'
          }),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('SfInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('gift-message-form-message'),expression:"'gift-message-form-message'"}],staticClass:"input--with-label gift-message-form__message",attrs:{"type":"text","name":"message-three","maxlength":_vm.maxLength,"label":_vm.$t('Your message 3'),"valid":!errors[0],"error-message":_vm.$t(errors[0]),"placeholder":' '},on:{"input":function($event){return _vm.getFormMessage(_vm.formMessages, 'message_three', $event)}},model:{value:(_vm.formMessages.message_three),callback:function ($$v) {_vm.$set(_vm.formMessages, "message_three", $$v)},expression:"formMessages.message_three"}}),_vm._v(" "),_c('div',{staticClass:"gift-message-form__counter"},[_c('span',[_vm._v(_vm._s(_vm.remainingCharacters.message_three))])])]}}],null,true)}),_vm._v(" "),_c('XmasForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.isJpStore),expression:"isJpStore"}]}),_vm._v(" "),_c('VaimoButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('gift-message-form-submit'),expression:"'gift-message-form-submit'"}],staticClass:"gift-message-form__submit-button",attrs:{"variant":"default","type":"submit","disabled":_vm.isLoading || invalid,"label-centered":true,"full-width":false,"data-testid":"gift-message-form-submit"}},[_c('span',[_vm._v(_vm._s(_vm.$t('Confirm message')))])]),_vm._v(" "),_vm._t("error")],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }