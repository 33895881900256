































































































































































































































































































































































































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  toRef,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import { debounce } from 'lodash-es';
import VaimoSlider from 'molecules/VaimoSlider.vue';
import ProductMainGallery from 'organisms/product/ProductMainGallery/index.vue';
import VaimoRelatedProductCard from 'organisms/VaimoRelatedProductCard.vue';

import { CmsBlock, useConfig, useContent } from '~/composables';
import { useUiState } from '~/composables/useUiState';
import {
  formatEventPrice,
  getEditorialData,
  itemBrand
} from '~/diptyqueTheme/composable/useGoogleTagManager/helpers';
import { useNarVar } from '~/diptyqueTheme/composable/useNarvar';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import productStatuses from '~/diptyqueTheme/config/productStatuses';
import soClozScript from '~/diptyqueTheme/customQueries/magento/soClozScript';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { useAddToCart } from '~/helpers/cart/addToCart';
import productGetters, {
  getBasePrice,
  getBreadcrumbs,
  getName as getProductName,
  getPrice as getProductPrice
} from '~/modules/catalog/product/getters/productGetters';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import { Product } from '~/modules/catalog/product/types';
import { useCartStore } from '~/modules/checkout/stores/cart';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { toggleZendeskWidget } from '~/diptyqueTheme/helpers/toggleZendeskWidget';

declare global {
  interface Window {
    Klarna: any;
  }
}
export default defineComponent({
  name: 'SimpleProduct',
  components: {
    ProductMainGallery,
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    EngravingInfoPleat: () =>
      import('organisms/product/EngravingInfoPleat.vue'),
    VaimoTryAndBuy: () => import('organisms/VaimoTryAndBuy.vue'),
    VaimoCrossSellProducts: () =>
      import('organisms/VaimoCrossSellProducts/VaimoCrossSellProducts.vue'),
    VaimoSidebar: () => import('organisms/VaimoSidebar.vue'),
    VaimoInformationPleats: () =>
      import('organisms/VaimoInformationPleats.vue'),
    VaimoSlider,
    VaimoRelatedProductCard,
    SectionsList: () => import('templates/SectionsList.vue'),
    ProductAvailabilityNotification: () =>
      import('organisms/product/ProductAvailabilityNotification.vue'),
    ProductReinsurance: () =>
      import('~/modules/catalog/product/components/ProductReinsurance.vue'),
    EngravingLayer: () => import('organisms/product/EngravingLayer.vue')
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null
    },
    breadcrumbs: {
      required: false,
      type: Array,
      default: () => []
    },
    isFetching: {
      type: Boolean,
      default: true
    },
    productBlocks: {
      required: false,
      type: Array,
      default: () => []
    },
    engravingConfig: {
      type: [Object, null],
      default: null
    }
  },
  setup(props) {
    const context = useContext();
    const {
      app: { $gtm }
    } = useContext();
    const product = toRef(props, 'product');
    const { isDesktop, isMobile } = useScreenSize();
    const { addOrRemoveItem, isInWishlist } = useWishlist();
    const { app } = useContext();
    const storeCode = app.i18n.localeProperties.code;
    const isJpStore = storeCode === 'ja_jp';
    const { config } = useConfig();
    const { addItemToCart, loading } = useAddToCart();
    const productStore = useProductStore();
    const { getEstimatedDeliveryDate, estimatedDeliveryDate, formatDate } =
      useNarVar();
    const { loadBlocks } = useContent();
    const isProductAvailabilityNotificationVisible = ref(false);
    const cartStore = useCartStore();
    const isMerging = computed(() => cartStore.is_data_loading);
    const {
      isProductZoomOpened,
      openEngravingInfo,
      hideEngravingInfo,
      isEngravingInfoOpen
    } = useUiState();

    const soClozHtml = ref(null);
    const isInformationPleatsSidebarOpen = ref(false);
    const pleatData = ref({});
    const getProductRevampImages = computed(() => props.product?.media_gallery);
    const handlePleatClick = (pleat) => {
      isInformationPleatsSidebarOpen.value = true;
      const regex = /{{media url=\&quot\;(\S+)\&quot\;}}/g;
      pleat.value = pleat.value.replace(
        regex,
        'https://www.diptyqueparis.com/media/$1'
      );
      pleatData.value = pleat;
    };

    const formatedDate = computed(() => {
      if (estimatedDeliveryDate.value) {
        return formatDate(estimatedDeliveryDate.value);
      }
    });

    const convertToMinorUnits = computed(() => {
      const minorUnitMultiplier = 100;
      const parsedPrice =
        typeof productPrice.value === 'string'
          ? parseFloat(productPrice.value)
          : productPrice.value;

      return Math.round(parsedPrice * minorUnitMultiplier);
    });

    const isKlarnaEnabled = computed(
      () => config.value?.klarna_pay_instalments_is_enabled ?? false
    );

    const isPriceEligibleForInstalments = computed(
      () =>
        productPrice.value >= +config.value?.klarna_pay_instalments_min_price
    );

    const klarnaPlacement = computed(() => {
      const transformedLocale =
        config.value?.klarna_pay_instalments_locale?.replace('_', '-');

      return `<klarna-placement
                class="item"
                data-key="credit-promotion-auto-size"
                data-locale="${transformedLocale}"
                data-purchase-amount=${convertToMinorUnits.value}
              >
              </klarna-placement>`;
    });

    const changeProductAvailabilityVisibility = () => {
      isProductAvailabilityNotificationVisible.value =
        !isProductAvailabilityNotificationVisible.value;
    };

    function formatNumber(num) {
      const roundedNum = Math.round(num * 10) / 10;
      if (roundedNum % 1 === 0) {
        return roundedNum.toFixed(0);
      }
      return roundedNum.toFixed(1);
    }

    const hideSidebar = () => {
      isInformationPleatsSidebarOpen.value = false;
      pleatData.value = {};
    };

    const isOutOfStock = computed(
      () => props.product?.stock_status === productStatuses.out_of_stock || !props.product?.is_salable
    );

    const isReplenishable = computed(() => !!props.product?.replenishable);

    const isPreOrder = computed(
      //@ts-ignore
      () => props.product?.preorder
    );

    const preOrderAddToCartLabel = computed(
      //@ts-ignore
      () => props.product?.preorderButtonLabel
    );

    const preOrderNoticeLabel = computed(
      //@ts-ignore
      () => props.product?.preorderStockNotice
    );

    const productShortDescription = computed(
      () => props.product?.pdp_short_description || ''
    );

    const productLongDescription = computed(
      () => props.product?.pdp_long_description || ''
    );

    const addProductToCart = (product) => {
      if (isMerging.value) {
        return;
      }

      addItemToCart(product);
    };

    const goToEngravingInfo = () => {
      openEngravingInfo();
    };

    watch(
      () => isEngravingInfoOpen.value,
      (newValue: boolean) => {
        productStore.isEngravingInfoOpen = newValue;
        if (isMobile.value) {
          toggleZendeskWidget(newValue);
        }
      }
    );

    const productPrice = computed(() => getProductPrice(props.product).regular);
    const productSpecialPrice = computed(
      () => getProductPrice(props.product).special
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const getProductSubtitle = computed(() => props.product?.subtitle);
    const getRelatedProducts = computed(() => {
      if (!props.product?.revamp_related_products?.length) return [];
      return [...props.product?.revamp_related_products].sort(
        (item1, item2) => +item1.position - +item2.position
      );
    });
    const getProductSKU = computed(() => props.product?.sku);
    const getCountryOfManufacture = computed(() => {
      return props.product?.country_of_manufacture || '';
    });
    const getProductNameObject = computed(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      if (props.product?.identity_name && props.product?.detailed_description) {
        return {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          title: props.product?.identity_name,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          subtitle: props.product?.detailed_description
        };
      }
    });
    const getCustomMessage = computed(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      () => props.product?.custom_message || ''
    );

    const getEngravingPleatText = computed(
      () => props.engravingConfig?.pleat_text
    );

    const getEngravingStatus = computed(() => props.engravingConfig?.status);
    const getEngravingConfig = computed(() => props.engravingConfig);

    const getRegularPrice = (product) => {
      return validateFraction(productGetters.getPrice(product).regular);
    };

    const getInformationPleatsPanelData = computed(() => {
      const availablePleats = [
        'description',
        'deco_know_how',
        'usage_tips',
        'formule',
        'deco_material_type',
        'ingredients'
      ];
      return availablePleats.reduce((memo, attributeCode) => {
        if (props.product[attributeCode]) {
          memo.push({
            key:
              attributeCode === 'description'
                ? props.product[attributeCode].label
                : props.product[attributeCode][0]?.label,
            value:
              attributeCode === 'description'
                ? props.product[attributeCode]['html']
                : props.product[attributeCode][0]['value']
          });
        }
        return memo;
      }, []);
    });

    const getCrossSellProducts = computed(() => {
      return props.product?.crosssell_products.filter((product) => product.is_salable) ?? [];
    });

    const isSoclozEnabled = computed(
      //@ts-ignore
      () => (config.value?.vaimo_socloz_reservation_enable === '1' && !props.product.preorder) ?? false
    );

    const getAndTriggerSoClozScript = async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore "customQueryCacheable" dynamically added by custom-middleware
      const { data } = await context.app.$vsf.$magento.api.customQueryCacheable(
        {
          query: soClozScript,
          queryVariables: {
            product_id: props.product?.id
          }
        }
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      soClozHtml.value = data?.reservationButton?.html;
      const buttonSpliterStart = data?.reservationButton?.html
        .toString()
        .split('<button ');
      const buttonSpliterEnd = buttonSpliterStart[1].split('</button>');
      soClozHtml.value = buttonSpliterEnd[1];

      const startIndex = soClozHtml.value.indexOf('(function ()');
      const soclozScriptString = soClozHtml.value
        .slice(startIndex)
        .replace(/<\/script>\s*<\/div>\s*$/, '');
      const soclozScript = new Function(soclozScriptString);
      soclozScript();
    };

    onBeforeMount(async () => {
      await getAndTriggerSoClozScript();
      await getEstimatedDeliveryDate();
      nextTick(() => {
        if (window.Klarna) {
          window.Klarna.OnsiteMessaging.refresh();
        }
      });
    });

    const updateBreadcrumbs = (breadcrumbs) => {
      return breadcrumbs.reduce((memo, cur) => {
        const updatedLink = app.localePath(cur.link.replace('/c/', '/l/'));
        memo.push({ ...cur, link: updatedLink });

        return memo;
      }, []);
    };
    const getProductAdditionalData = computed(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const getEditorialLabels = props.product?.editorial?.reduce(
        (memo, cur) => {
          if (cur?.label !== 'false') {
            memo.push(cur.label);
          }
          return memo;
        },
        []
      );
      const engravingStatus = props.engravingConfig?.status
      return {
        editorial: getEditorialLabels,
        breadcrumbs: updateBreadcrumbs(props.breadcrumbs),
        engraving: engravingStatus
      };
    });

    const $carousel = ref(null);
    const updateCarousel = (updated) => {
      $carousel.value = updated.value;
    };

    const actionPanelElement = ref(null);
    const isPanelFixed = ref(false);
    const SCROLL_DEBOUNCE = 300;
    const handleScroll = debounce((e) => {
      const isPanelFixedEdge =
        actionPanelElement.value &&
        actionPanelElement.value.getBoundingClientRect().top;
      if (!isPanelFixed.value && isPanelFixedEdge <= 0) {
        isPanelFixed.value = true;
      } else if (isPanelFixed.value && isPanelFixedEdge > 0) {
        isPanelFixed.value = false;
      }
    }, SCROLL_DEBOUNCE);

    const fixedPanelHeight = 54;
    const setBottomPadding = (value) => {
      if (isDesktop.value) return false;
      document.getElementById('__layout').style.paddingBottom = value + 'px';
    };

    const productCategories = product.value?.categories ?? [];
    /* OTIz = id 923 -> category only for candles */
    const isCandle = productCategories.find((item) => item.uid === 'OTIz')
      ? true
      : false;

    const getClickStorePickupDetails = () => {
      const categories = getBreadcrumbs(
        product.value,
        Array.isArray(productCategories) ? [...productCategories].pop() : null
      )
        .slice(0, -1)
        .map((cat) => cat.text);
      const regularPrice = productGetters.getPrice(product.value).regular;

      $gtm.push({
        event: 'clickStorePickup',
        item_name: product.value?.name,
        item_id: product.value?.sku,
        price: formatEventPrice(regularPrice),
        item_brand: itemBrand,
        item_category: categories[0] || '',
        item_category2: categories[1] || '',
        item_category3: categories[2] || '',
        item_variant: getEditorialData(product.value),
        item_list_name: 'pdp'
      });
    };

    const cmsBlock = ref<CmsBlock>({});
    const cmsBlocks = ref<CmsBlock[]>([]);
    const isCmsSidebarVisible = ref<boolean>(false);
    const openCmsBlockByType = (identifier: string): void => {
      const currentBlock = cmsBlocks.value.find(
        (block) => block?.identifier === identifier
      );
      if (currentBlock) {
        cmsBlock.value = {
          content: currentBlock.content,
          title: currentBlock.title
        };
        isCmsSidebarVisible.value = true;
      }
    };

    const hideCmsSidebar = () => {
      isCmsSidebarVisible.value = false;
      cmsBlock.value = {};
    };

    onMounted(async () => {
      window.scrollTo(0, 0);
      cmsBlocks.value = await loadBlocks({
        identifiers: ['pdp_returns', 'pdp_delivery']
      });
      actionPanelElement.value = document.querySelector<HTMLElement>(
        '.product__action-buttons'
      );
      window.addEventListener('scroll', handleScroll);
      setBottomPadding(fixedPanelHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      setBottomPadding(0);
    });

    return {
      getProductNameObject,
      changeProductAvailabilityVisibility,
      isProductAvailabilityNotificationVisible,
      getProductAdditionalData,
      getCrossSellProducts,
      getInformationPleatsPanelData,
      getRegularPrice,
      getBasePrice,
      isDesktop,
      getCustomMessage,
      addItemToWishlist: addOrRemoveItem,
      getProductSKU,
      addProductToCart,
      goToEngravingInfo,
      openEngravingInfo,
      hideEngravingInfo,
      isEngravingInfoOpen,
      loading,
      getCountryOfManufacture,
      soClozHtml,
      isInWishlist: computed(() => isInWishlist({ product: props.product })),
      productShortDescription,
      productLongDescription,
      hideSidebar,
      isInformationPleatsSidebarOpen,
      handlePleatClick,
      pleatData,
      getProductName,
      productPrice,
      formatNumber,
      productSpecialPrice,
      isOutOfStock,
      getProductSubtitle,
      getRelatedProducts,
      updateCarousel,
      isPanelFixed,
      formatedDate,
      getClickStorePickupDetails,
      klarnaPlacement,
      isKlarnaEnabled,
      isPriceEligibleForInstalments,
      getProductRevampImages,
      openCmsBlockByType,
      isCmsSidebarVisible,
      cmsBlock,
      hideCmsSidebar,
      validateFraction,
      isCandle,
      isMerging,
      isSoclozEnabled,
      isPreOrder,
      preOrderAddToCartLabel,
      preOrderNoticeLabel,
      isJpStore,
      isProductZoomOpened,
      isReplenishable,
      getEngravingPleatText,
      getEngravingStatus,
      getEngravingConfig
    };
  }
});
