



























































































































































































import {
  computed,
  defineComponent, onBeforeUnmount,
  onMounted,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import { SfButton, SfHeading, SfLoader, SfRadio } from '@storefront-ui/vue';

import { useAvailableShippingMethodsStore } from '@/diptyqueTheme/stores/checkout';
import {useApi, useConfig, useStore, useUiNotification} from '~/composables';
import { useNarVar } from '~/diptyqueTheme/composable/useNarvar';
import { useJpDeliveryDate } from '~/diptyqueTheme/composable/useJpDeliveryDate';
import setClickAndCollectShop from '~/diptyqueTheme/customQueries/magento/setClickAndCollectShopMutation';
import updateCartTotalsMutation from '~/diptyqueTheme/customQueries/magento/updateCartTotalsMutation';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { useSelectedShippingMethodStore } from '~/diptyqueTheme/stores/selectedShippingMethod';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import { Logger } from '~/helpers/logger';
import useCart from '~/modules/checkout/composables/useCart';
import useShipping from '~/modules/checkout/composables/useShipping';
import useShippingProvider from '~/modules/checkout/composables/useShippingProvider';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';
import { useUser } from '~/modules/customer/composables/useUser';

import type {
  AvailableShippingMethod,
  SelectedShippingMethod,
  ShippingCartAddress
} from '~/modules/GraphQL/types';
import {useCartStore} from "~/modules/checkout/stores/cart";

interface boutiqueinterface {
  id: string | null;
  external_id: string | null;
  name: string | null;
  address: string | null;
  zip_code: string | null;
  city: string | null;
  distance: number | null;
  phone_number: string | null;
  scheduled_states: string | [] | null;
}

interface UpdateCartTotalsInterface {
  data: {
    updateCartTotals: {
      cart: { [key: string]: any };
    };
  };
}

export default defineComponent({
  name: 'VaimoShippingProvider',
  components: {
    SfHeading,
    SfButton,
    SfRadio,
    SfLoader,
    VaimoMultiselect: () => import('atoms/VaimoMultiselect.vue'),
  },
  scrollToTop: true,
  setup(_, { emit }) {
    const { cart, load, mergeCartItems } = useCart();
    const { app } = useContext();
    const state = ref<SelectedShippingMethod | null>(null);
    const shipppingAddressState = ref<ShippingCartAddress | null>(null);
    const shippingMethodsStore = useAvailableShippingMethodsStore();
    const shippingMethods = computed(():AvailableShippingMethod[] => shippingMethodsStore.shippingMethods);
    const availableMethods = computed((): AvailableShippingMethod[] => {
      let methods = [];
      if (shippingMethodsStore.shippingMethods) {
        methods = shippingMethods.value.filter(
          (method) => method?.available === true
        );
      }
      return methods;
    });
    const restrictedShippingMethodMessage = computed(
      () =>
        shippingMethods.value?.find((method) => method.error_message)
          ?.error_message
    );
    const selectedShippingMethodStore = useSelectedShippingMethodStore();
    const {
      config: {
        value: { store_code }
      }
    } = useConfig();
    const { getEstimatedDeliveryDates, estimationDeliveryDates, formatDate } =
      useNarVar();
    const {
      loadCurrentJpDeliveryDateForm,
      isEnableJpDeliveryDate,
      isEnableJpDeliveryTime,
      jpDeliveryFromDate,
      jpDeliveryToDate,
      jpDeliveryDate,
      jpDeliveryTime,
      jpDeliveryTimeList,
      isCartSaving,
      saveJpDeliveryDateToCartError,
      isPendingForJpDeliveryDate,
      jpDateFormat,
      disabledJpDeliveryDays,
      saveJpDeliveryDateToCart
    } = useJpDeliveryDate();
    const { send: sendNotification } = useUiNotification();
    const { mutate } = useApi();
    const { isAuthenticated } = useUser();
    const { isJpStore } = useStore();

    const {
      save: saveShippingProvider,
      error: errorShippingProvider,
      loading: isLoading
    } = useShippingProvider();
    const isSubmitting = ref(false);
    const isSelecting = ref(false);
    const isBillingSaved = ref(false);

    const { save: saveShipping } = useShipping();
    const clickAndCollectMethodName = 'expressclickandcollect';
    const togglebButiquesList = ref(false);

    const selectedShippingMethod = computed(() => selectedShippingMethodStore.selectedShippingMethodData);
    const totals = computed(() => cartGetters.getTotals(cart.value));
    const isShippingMethodStepCompleted = computed(
      () => selectedShippingMethod.value?.method_code
    );

    const isAllowJpDeliveryDate = computed(() => {
      return loadCurrentJpDeliveryDateForm(selectedShippingMethod.value?.carrier_code, shippingMethods.value);
    });

    const updateTotals = async () => {
      const cartId = cart.value?.id;
      selectedShippingMethodStore.isTotalsUpdating = true;

      try {
        const { data }: UpdateCartTotalsInterface = await mutate(
          updateCartTotalsMutation,
          {
            input: {
              cart_id: cartId
            }
          }
        );
        data?.updateCartTotals?.cart &&
          mergeCartItems(data?.updateCartTotals?.cart);
        if (data?.updateCartTotals?.cart) {
          selectedShippingMethodStore.shippingTitle = {
            carrier_title:
              data?.updateCartTotals?.cart?.shipping_addresses?.[0]
                .selected_shipping_method.carrier_title,
            method_title:
              data?.updateCartTotals?.cart?.shipping_addresses?.[0]
                .selected_shipping_method.method_title
          };
        }
      } catch (e) {
        console.error(e);
      } finally {
        selectedShippingMethodStore.isTotalsUpdating = false;
      }
    };

    const selectShippingMethod = async (method: SelectedShippingMethod) => {
      const shippingData = {
        carrier_code: method.carrier_code,
        method_code: method.method_code
      };
      selectedShippingMethodStore.isSelectionInProgress = true;
      jpDeliveryDate.value = '';
      jpDeliveryTime.value = '';
      isEnableJpDeliveryDate.value = 0;
      isEnableJpDeliveryTime.value = 0;
      selectedShippingMethodStore.setShippingMethod(method);
      isSelecting.value = true;

      try {
        const newSelectedMethod = await saveShippingProvider({
          shippingMethod: shippingData,
          customQuery: {
            setShippingMethodsOnCart: 'customSetShippingMethodsOnCart'
          }
        });

        selectedShippingMethodStore.isSelectionInProgress = false;

        selectedShippingMethodStore.setShippingMethod(newSelectedMethod);
      } catch (e) {
        console.error(e);
      } finally {
        isSelecting.value = false;
        selectedShippingMethodStore.isSelectionInProgress = false;
        await updateTotals();
      }
    };

    const saveShippingAddressData = async (shippingDetailsData) => {
      await mergeItem('checkout', { 'shipping-address': shippingDetailsData });
      await saveShipping({
        shippingDetails: shippingDetailsData,
        customQuery: {
          setShippingAddressesOnCart: 'customSetShippingAddressesOnCart'
        }
      });
    };

    const updateClickAndCollectShop = async (boutique) => {
      const cartId = cart.value?.id;
      isSelecting.value = true;

      try {
        await mutate(setClickAndCollectShop, {
          cartId,
          shopInput: {
            name: boutique.name ?? '',
            external_id: boutique.id ?? '',
            address: boutique.street ?? '',
            phone_number: boutique.telephone ?? '',
            scheduled_states: boutique.scheduled_states
              ? JSON.stringify(boutique.scheduled_states)
              : ''
          }
        });
      } catch (err) {
        Logger.error('updateClickAndCollectShop', err);
        sendNotification({
          id: Symbol('set_click_and_collect_shop_error'),
          message: err.message,
          type: 'danger',
          persist: false,
          title: app.i18n.t('Something went wrong!') as string
        });
      } finally {
        isSelecting.value = false;
      }
    };

    const boutiqueList = ref([]);
    const selectedBoutique = ref(null);

    const setSelectedBoutique = (method, id) => {
      selectedBoutique.value = boutiqueList.value.find(
        (boutique) => boutique.id === id
      );
      togglebButiquesList.value = false;
    };

    const convertAddressString = (addr) => {
      if (!addr) {
        return '';
      }

      let address = addr.toLowerCase(),
        words = address.split(' ');

      const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });

      address = capitalizedWords.join(' ');

      return address;
    };

    const getAvailableStores = computed(() => {
      if (!shippingMethods?.value || !shippingMethods.value.length) {
        return false;
      }

      const boutiquesDataString = shippingMethods.value.find(
        (sm) => sm.method_code === clickAndCollectMethodName
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
      )?.additional_data;

      if (!boutiquesDataString) {
        return false;
      }

      const boutiquesData = JSON.parse(boutiquesDataString);
      if (
        Object.keys(boutiquesData).length > 0 &&
        // eslint-disable-next-line no-prototype-builtins
        boutiquesData.hasOwnProperty('boutique_list')
      ) {
        const storeCode = store_code,
          unit = storeCode && storeCode === 'en_us' ? ' mi' : ' km';
        const boutiqueArray = Object.values<boutiqueinterface>(
          boutiquesData.boutique_list
        ).map((el) => ({
          id: el.external_id,
          name: el.name,
          street: convertAddressString(el.address),
          postcode: el.zip_code,
          city: el.city,
          distance: el.distance,
          telephone: el.phone_number,
          scheduled_states: el.scheduled_states,
          boutiqueInfo:
            convertAddressString(el.address) +
            ', ' +
            el.zip_code +
            ', ' +
            convertAddressString(el.city) +
            ' (' +
            // eslint-disable-next-line no-magic-numbers
            (el.distance / 1000).toFixed(1) +
            unit +
            ')'
        }));

        boutiqueArray.sort((a, b) => a.distance - b.distance);
        boutiqueList.value = boutiqueArray;

        return boutiqueArray;
      }

      return false;
    });

    watch(boutiqueList, () => {
      if (getAvailableStores.value && getAvailableStores.value.length > 0) {
        selectedBoutique.value = getAvailableStores.value[0];
      }
    });

    const isTotalsUpdatingAndCollectSelected = computed(
      () =>
        selectedShippingMethod.value &&
        selectedShippingMethod.value.method_code ===
          clickAndCollectMethodName &&
        selectedShippingMethodStore.isTotalsUpdating
    );

    const onContinueToBillingClick = async () => {
      isSubmitting.value = true;
      const vsfCheckoutData = lsGet('vsf-checkout');

      const shipppingAddress =
        vsfCheckoutData && vsfCheckoutData['shipping-address']
          ? vsfCheckoutData['shipping-address']
          : null;

      if (
        selectedShippingMethod.value &&
        selectedShippingMethod.value.method_code === clickAndCollectMethodName
      ) {
        if (shipppingAddress && selectedBoutique.value) {
          const shippingDetailsData = {
            ...shipppingAddress,
            city: selectedBoutique.value.city,
            postcode: selectedBoutique.value.postcode,
            street: selectedBoutique.value.street,
            company: null,
            default_shipping: false,
            storeAddress: true,
            region: {
              region_code: shipppingAddress?.region?.region_code ?? '',
              region: shipppingAddress?.region?.region ?? ''
            }
          };

          saveShippingAddressData(shippingDetailsData);
          updateClickAndCollectShop(selectedBoutique.value);
        }
      }

      if (selectedShippingMethodStore.selectedShippingMethodData?.method_code) {
        selectedShippingMethodStore.shippingTitle = {
          carrier_title:
            selectedShippingMethodStore.selectedShippingMethodData
              .carrier_title,
          method_title:
            selectedShippingMethodStore.selectedShippingMethodData.method_title
        };
      }
      if (isJpStore.value) {
        emit('call-loader', true);
        const saveResult = await saveJpDeliveryDateToCart(cart.value?.id, selectedShippingMethod.value.method_code);
        isSubmitting.value = false;
        if (!saveResult) {
          saveJpDeliveryDateToCartError.value = true;
          emit('call-loader', false);
          return false;
        }
        saveJpDeliveryDateToCartError.value = false;
        emit('call-loader', false);
      }
      isSubmitting.value = false;
      emit('submit');
    };

    watch(jpDeliveryDate, (newDeliveryDate, oldDeliveryDate) => {
      let error = false;
      let date = new Date(newDeliveryDate);
      if (newDeliveryDate && newDeliveryDate.length > 0 && disabledJpDeliveryDays(date)) {
        error = true
      }
      saveJpDeliveryDateToCartError.value = error;
    });

    const shippingDataWithDates = computed(() => {
      if (estimationDeliveryDates.value && availableMethods.value) {
        return availableMethods.value.reduce((memo, cur) => {
          const methodTitle = cur.method_title;
          const matchedKey = Object.keys(estimationDeliveryDates.value).find(
            (key) => methodTitle.toLowerCase().includes(key)
          );
          if (matchedKey) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            cur.shipping_date = app.i18n.t('Estimated delivery date {date}', {
              date: formatDate(estimationDeliveryDates.value[matchedKey])
            }) as string;
          }
          memo.push(cur);
          return memo;
        }, []);
      }
      return availableMethods.value;
    });

    const requestIntervalId = ref(null);
    const messageIntervalId = ref(null);
    const attemptCounter = ref(0);
    const attemptText = ref('');
    const isShippingMethodsLoading = ref(false);
    const MAX_ATTEMPTS = 3;

    const setShippingMethods = async () => {
      try {
        shippingMethodsStore.isPending = true;
        isShippingMethodsLoading.value = true;
        await load();
        if (
          cart.value?.shipping_addresses[0]?.available_shipping_methods?.length
        ) {
          const shippingMethods =
            cart.value.shipping_addresses[0].available_shipping_methods;
          shippingMethodsStore.setShippingMethods(shippingMethods);
          shippingMethodsStore.isPending = false;
        }
      } catch (e) {
        console.error(e);
      } finally {
        isShippingMethodsLoading.value = false;
      }
    };

    const updateAttemptText = () => {
      attemptCounter.value++;
      switch (attemptCounter.value) {
        case 1:
          attemptText.value = app.i18n.t(
            "Please wait and don't refresh the page"
          ) as string;
          break;
        case 2:
          attemptText.value = app.i18n.t(
            'We are calculating your shipping methods'
          ) as string;
          break;
        case 3:
          attemptText.value = app.i18n.t(
            'Please wait, almost ready...'
          ) as string;
          break;
        default:
          attemptText.value = app.i18n.t('Unexpected attempt count') as string;
          break;
      }
      if (attemptCounter.value >= MAX_ATTEMPTS) {
        attemptCounter.value = 0;
      }
    };

    const loadCart = async () => {
      if (!shippingMethodsStore.shippingMethods?.length) {
        updateAttemptText();
        await setShippingMethods();
        if (attemptCounter.value >= MAX_ATTEMPTS) {
          clearInterval(requestIntervalId.value);
          clearInterval(messageIntervalId.value);
        }
      } else {
        clearInterval(requestIntervalId.value);
        clearInterval(messageIntervalId.value);
      }
    };

    onBeforeUnmount(() => {
      clearInterval(requestIntervalId.value);
      clearInterval(messageIntervalId.value);
    });

    onMounted(async () => {
      window.scroll(0, 0);
      selectedShippingMethodStore.shippingTitle = null;

      isSelecting.value = true;
      // we need to load cart here cause without it, we have a bug:
      // https://jira.vaimo.com/browse/DPTQ-3497
      // so, we definitely need to improve this, but without creating a new bug

      loadCart(); //Send the first request immediately without blocking
      messageIntervalId.value = setInterval(updateAttemptText, 3000);
      requestIntervalId.value = setInterval(loadCart, 15000);

      const isSelectedShippingMethodStoreEmpty = !(selectedShippingMethodStore.selectedShippingMethodData?.method_code);
      if (
        isSelectedShippingMethodStoreEmpty &&
        cart.value?.shipping_addresses?.[0]?.selected_shipping_method
      ) {
        selectedShippingMethodStore.setShippingMethod(cart.value?.shipping_addresses?.[0]?.selected_shipping_method);
      }

      isSelecting.value = false;

      if (cart.value && cart.value.id) {
        await getEstimatedDeliveryDates(cart.value.id);
      }

      if (
        selectedShippingMethod.value === null ||
        (selectedShippingMethod.value && selectedShippingMethod.value.method_code !== clickAndCollectMethodName)
      ) {
        const vsfCheckoutData = lsGet('vsf-checkout');
        shipppingAddressState.value =
          vsfCheckoutData && vsfCheckoutData['shipping-address']
            ? vsfCheckoutData['shipping-address']
            : null;

      } else {
        isSelecting.value = false;
      }

      if (!shippingMethodsStore.isPending) {
        isBillingSaved.value = true;
        isSelecting.value = false;
      }

    });

    return {
      isSubmitting,
      isBillingSaved,
      isSelecting,
      errorShippingProvider,
      getShippingMethodPrice,
      isLoading,
      isShippingMethodStepCompleted,
      selectedShippingMethod,
      selectShippingMethod,
      state,
      shipppingAddressState,
      totals,
      shippingMethods,
      restrictedShippingMethodMessage,
      shippingDataWithDates,
      validateFraction,
      getAvailableStores,
      boutiqueList,
      selectedBoutique,
      setSelectedBoutique,
      attemptText,
      onContinueToBillingClick,
      saveShippingAddressData,
      togglebButiquesList,
      disabledJpDeliveryDays,
      jpDeliveryFromDate,
      jpDeliveryToDate,
      isEnableJpDeliveryDate,
      isEnableJpDeliveryTime,
      isAllowJpDeliveryDate,
      isPendingForJpDeliveryDate,
      isCartSaving,
      saveJpDeliveryDateToCartError,
      jpDateFormat,
      jpDeliveryDate,
      jpDeliveryTime,
      jpDeliveryTimeList,
      isShippingMethodsLoading,
      isPending: computed(() => shippingMethodsStore.isPending),
      isBillingPending: computed(() => shippingMethodsStore.isBillingPending),
      isSelectionInProgress: computed(
        () => selectedShippingMethodStore.isSelectionInProgress
      ),
      isTotalsUpdating: computed(
        () => selectedShippingMethodStore.isTotalsUpdating
      ),
      isTotalsUpdatingAndCollectSelected
    };
  }
});
