


























import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'LoyaltyPush',
  components: {
    VaimoSectionTemplate: () => import('molecules/VaimoSectionTemplate.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue')
  },
  props: {
    uniqueKey: {
      required: true,
      type: String
    },
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const items = computed(
      () => props.sectionData?.itemsCollection?.items || []
    );

    const footerVisible = computed(() => {
      return (
        props.sectionData?.buttonCollection?.items.filter((b) => b).length > 0
      );
    });

    const headerVisible = computed(() => {
      return !!(props.sectionData.heading || props.sectionData.descr);
    });

    return {
      footerVisible,
      headerVisible,
      items
    };
  }
});
