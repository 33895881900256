<template>
  <transition name="sf-fade" mode="ease">
    <button
      class="scroll-to-top title-h5 s:text-sm"
      v-if="showLink"
      :class="{ 'lifted' : lifted }"
      @click.ative="scrollToTop"
    >
      {{ $t('Back to top') }}
      <VaimoIcon
        icon="arrowup_gris_perle"
        :size="15"
        class="icon align-text-bottom ml-2px"
        :label="$t('Back to top')"
      />
    </button>
  </transition>
</template>

<script>
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'VaimoScrollToTop',
  props: {
    lifted: {
      type: Boolean,
      default: false
    },
  },
    setup() {
      const showLink = ref(false)

      const isScroll = () => {
        showLink.value = window.scrollY > 0
      }

      const scrollToTop = () => {
        window.scrollTo(0,0);
      }

      onMounted(() => {
        if (process.client) {
          window.addEventListener('scroll', isScroll);
        }
      })

      onBeforeUnmount(() => {
        window.removeEventListener('scroll', isScroll);
      })

      return {
        showLink,
        scrollToTop
      };
    }
})

</script>

<style lang="scss" scoped>
.scroll-to-top {
  padding: 2px 6px 1px 10px;
  outline: none;
  height: 22px;
  border: 0.8px solid $gray_light;
  border-radius: 22px;
  position: fixed;
  bottom: var(--spacer-4md);
  left: 50%;
  transform: translateX(-50%);
  background-color: $blanc;
  color: $gris_perle;
  text-transform: uppercase;
  z-index: 5;
  @include for-mobile {
    bottom: var(--spacer-md);
  }

  &.lifted {
    @include for-mobile {
      bottom: 80px;
    }
  }
}

</style>
