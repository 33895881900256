























































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import VaimoBadge from 'atoms/VaimoBadge.vue';
import VaimoBooxiLifeTimeLabel from 'organisms/cart/parts/VaimoBooxiLifeTimeLabel.vue';
import VaimoEngravingCard from 'organisms/cart/parts/VaimoEngravingCard.vue';

import CouponCode from '~/components/CouponCode.vue';
import { useConfig } from '~/composables';
import { useScreenSize } from '~/diptyqueTheme/composable';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import Totals from '~/diptyqueTheme/modules/checkout/components/Totals.vue';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import useCart from '~/modules/checkout/composables/useCart';
import cartGetters from '~/modules/checkout/getters/cartGetters';

export default defineComponent({
  name: 'CartPreview',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    CouponCode,
    Totals,
    VaimoBadge,
    VaimoBooxiLifeTimeLabel,
    VaimoEngravingCard
  },
  setup() {
    const { cart, removeItem, updateItemQty } = useCart();
    const { isDesktop } = useScreenSize();
    const listIsHidden = ref(false);
    const { booxiLifeTimeData } = useBooxi();
    const { config } = useConfig();

    const getProductPrice = (product) => {
      const { config } = useConfig();
      const cartProductPriceDisplayConfig =
        config.value?.tax_cart_display_price;
      return (
        (cartProductPriceDisplayConfig === '2'
          ? product.prices?.row_total_including_tax.value || 0
          : product.prices?.row_total.value) || 0
      );
    };

    const products = computed(() => {
      const productsArr = cartGetters.getItems(cart.value);

      if (productsArr?.length) {
        return productsArr.filter((item) => item);
      }

      return productsArr;
    });

    const isEngraving = (product) => {
      return product.engraving ? true : false;
    };

    const isSampleProduct = (product) => {
      const xmasSkus = config.value?.xmas_skus;
      if (
        Array.isArray(xmasSkus) &&
        xmasSkus.includes(cartGetters.getItemSku(product))
      ) {
        return false;
      }

      return cartGetters.getItemPrice(product).regular === 0;
    };

    const getBooxiLifetime = (product) =>
      product?.booxi_item_info?.expired_time;

    const isVirtualCartItem = (product) =>
      product.__typename === 'VirtualCartItem';

    return {
      cart,
      isDesktop,
      listIsHidden,
      products,
      isEngraving,
      isSampleProduct,
      removeItem,
      updateItemQty,
      cartGetters,
      getShippingMethodPrice,
      productGetters,
      validateFraction,
      getProductPrice,
      booxiLifeTimeData,
      isVirtualCartItem,
      getBooxiLifetime
    };
  }
});
