












































































import {
  computed,
  defineComponent,
  PropType,
  ref
} from '@nuxtjs/composition-api';
import VaimoProgressBar from 'atoms/VaimoProgressBar.vue';
import { AudioProp, ImageProp } from 'molecules/VaimoAudioPlayer/models';

import { useScreenSize } from '~/diptyqueTheme/composable';
import { useRepeatAction } from '~/diptyqueTheme/composable/useRepeatAction';

export default defineComponent({
  name: 'VaimoAudioPlayer',
  components: { VaimoProgressBar },
  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    image: { type: Object as PropType<ImageProp | null>, default: null },
    audio: { type: Object as PropType<AudioProp>, required: true },
    autoplay: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    skipIntervalInSeconds: { type: Number, default: 15 }
  },
  setup(props) {
    const audioRef = ref<HTMLAudioElement | null>(null);
    const duration = ref(0);
    const currentTime = ref(0);
    const isPlaying = ref(false);
    const wasPlayingBeforeSeek = ref(false);

    const play = () => {
      audioRef.value?.play();
    };
    const pause = () => {
      audioRef.value?.pause();
    };

    const onPlay = () => {
      isPlaying.value = true;
    };
    const onPause = () => {
      isPlaying.value = false;
    };

    const setDuration = () => {
      if (audioRef.value?.readyState > 1)
        duration.value = audioRef.value.duration;
    };

    const resetPlayer = () => {
      audioRef.value.currentTime = 0;
      if (isSkippingForward.value) clearSkipForward();
      else if (props.loop) play();
    };

    const skipBack = () => {
      if (audioRef.value)
        audioRef.value.currentTime = Math.max(
          audioRef.value.currentTime - props.skipIntervalInSeconds,
          0
        );
    };

    const skipForward = () => {
      if (audioRef.value) {
        const newTime =
          audioRef.value.currentTime + props.skipIntervalInSeconds;
        if (newTime >= duration.value) {
          audioRef.value.currentTime = duration.value;
          if (isPlaying.value) pause();
        } else {
          audioRef.value.currentTime = newTime;
        }
      }
    };

    const { startRepeat: startSkipBack, clearRepeat: clearSkipBack } =
      useRepeatAction(skipBack);

    const {
      startRepeat: startSkipForward,
      clearRepeat: clearSkipForward,
      isRepeating: isSkippingForward
    } = useRepeatAction(skipForward);

    const updateProgress = () => {
      if (audioRef.value) currentTime.value = audioRef.value.currentTime;
    };

    const setCurrentTime = (time: number) => {
      if (audioRef.value) {
        currentTime.value = time;
        audioRef.value.currentTime = time;
      }
    };

    const progress = computed(() => {
      const hundred = 100;

      return duration.value > 0
        ? (currentTime.value / duration.value) * hundred
        : 0;
    });

    const timeLeft = computed(() => {
      const timeRemaining = duration.value - currentTime.value;

      const secondsInMinute = 60;
      const minutes = Math.floor(timeRemaining / secondsInMinute);
      const seconds = Math.floor(timeRemaining % secondsInMinute);

      const minutesChars = 2;
      return `${minutes}:${String(seconds).padStart(minutesChars, '0')}`;
    });

    const onSeekingStart = () => {
      wasPlayingBeforeSeek.value = isPlaying.value;
      pause();
    };

    const onSeekingEnd = () => {
      wasPlayingBeforeSeek.value && play();
    };

    const { isDesktop } = useScreenSize();

    const showMinus = computed(() => currentTime.value !== 0);

    return {
      audioRef,
      setDuration,
      resetPlayer,
      updateProgress,
      onPlay,
      onPause,
      play,
      pause,
      timeLeft,
      skipBack,
      skipForward,
      isPlaying,
      progress,
      setCurrentTime,
      startSkipBack,
      clearSkipBack,
      startSkipForward,
      clearSkipForward,
      isDesktop,
      duration,
      currentTime,
      onSeekingStart,
      onSeekingEnd,
      showMinus
    };
  }
});
