<template>
  <div
    class="vaimo-section-template vst grid grid-cols-12"
    :class="templateClasses"
  >
    <div
      v-if="hasAboveVisual"
      class="vst__above-visual"
      :class="aboveVisualClasses"
    >
      <slot name="aboveVisual" />
    </div>

    <div v-if="hasVisual" class="vst__visual" :class="visualClasses">
      <div class="vst__visual-inner">
        <slot name="visual">
          <VaimoBanner
            v-bind="getVisualData"
            :max-optimised-width="visualMaxOptimizedWidth"
          />
          <VaimoButtons
            v-if="buttonsPosition === 'visual'"
            block
            :section-data="_sectionData"
            position-filter="Default"
            class="module__buttons in-visual"
          />
        </slot>
      </div>
    </div>

    <div v-if="hasHeader" class="vst__header" :class="headerClasses">
      <slot name="header">
        <VaimoHeading
          v-if="_sectionData.heading"
          :heading="_sectionData.heading"
          :heading-link="_sectionData.headingLink"
          :heading-level="headingLevel"
          class="module__heading"
          @click="$emit('heading-click')"
        />

        <VaimoTruncatedText
          v-if="_sectionData.descr"
          :text="_sectionData.descr"
          class="module__descr paragraph-3"
          markdown
          @click.native="$emit('description-click')"
        />

        <VaimoButtons
          v-if="buttonsPosition === 'header'"
          :section-data="_sectionData"
          position-filter="Default"
          class="module__buttons in-header"
        />
      </slot>
    </div>

    <div v-if="hasContent" class="vst__content" :class="contentClasses">
      <div class="vst__content-inner">
        <slot />
      </div>
    </div>

    <div v-if="hasFooter" class="vst__footer" :class="footerClasses">
      <slot name="footer">
        <VaimoButtons
          v-if="buttonsPosition === 'footer'"
          :section-data="_sectionData"
          position-filter="Bottom"
          class="module__buttons in-footer"
        />
      </slot>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from '@nuxtjs/composition-api';

import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'VaimoSectionTemplate',
  components: {
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    },
    template: {
      type: String,
      required: false,
      default: undefined,
      validator: (value) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return ['visual-top', 'visual-left', 'visual-right'].includes(value);
      }
    },
    templateDesktop: {
      type: String,
      required: false,
      default: undefined,
      validator: (value) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return ['visual-top', 'visual-left', 'visual-right'].includes(value);
      }
    },
    headingLevel: {
      type: String,
      required: false,
      default: 'h2'
    },
    aboveVisualVisible: {
      type: Boolean
    },
    headerVisible: {
      type: [Boolean, undefined],
      default: undefined
    },
    contentVisible: {
      type: Boolean,
      default: true
    },
    footerVisible: {
      type: [Boolean, undefined],
      default: undefined
    },
    visualOut: {
      type: Boolean
    },
    visualOutD: {
      type: Boolean
    },
    contentOut: {
      type: Boolean
    },
    contentOutD: {
      type: Boolean
    },
    visualMaxOptimizedWidth: {
      type: Number,
      required: false,
      default: 1240
    },
    aboveVisualClass: {
      type: String,
      required: false,
      default: ''
    },
    visualClass: {
      type: String,
      required: false,
      default: ''
    },
    headerClass: {
      type: String,
      required: false,
      default: ''
    },
    contentClass: {
      type: String,
      required: false,
      default: ''
    },
    footerClass: {
      type: String,
      required: false,
      default: ''
    },
    wrapperContainer: {
      type: String,
      required: false,
      default: 'auto'
    },
    buttonsPosition: {
      required: false,
      type: String,
      default: 'header',
      validator: (value) => ['header', 'footer', 'visual'].includes(value)
    },
    visualCols: Number,
    visualColsD: Number,
    contentCols: Number,
    contentColsD: Number
  },
  emits: ['heading-click', 'description-click'],
  setup(props) {
    const MODULE_DEFAULT = 'Default for current module';

    const _sectionData = computed(() => {
      const _sectionData = { ...props.sectionData };
      _sectionData.buttonCollection?.items.forEach((btn) => {
        if (btn) preprocessButtonPosition(btn);
      });

      return _sectionData;
    });

    const hasHeaderButtons = ref(false);
    const hasFooterButtons = ref(false);
    const preprocessButtonPosition = (btn) => {
      if (props.buttonsPosition === 'footer') {
        btn.position = 'Bottom';
      } else {
        btn.position = btn.position || 'Default';
      }

      if (btn.position === 'Default') {
        hasHeaderButtons.value = true;
      } else if (btn.position === 'Bottom') {
        hasFooterButtons.value = true;
      }
    };

    const { isDesktop, getVisualData, getButtonDataFunction } = useDeclination(
      _sectionData.value
    );

    const hasAboveVisual = computed(() => {
      if (props.aboveVisualVisible !== undefined)
        return props.aboveVisualVisible;
      return false;
    });

    const hasVisual = computed(() => {
      if (!_sectionData.value?.visual) return false;
      return (
        _sectionData.value.visual.vis ||
        (isDesktop.value && _sectionData.value.visual.visD)
      );
    });

    const hasHeader = computed(() => {
      if (props.headerVisible !== undefined) return props.headerVisible;
      return (
        _sectionData.value?.heading ||
        _sectionData.value?.descr ||
        hasHeaderButtons.value
      );
    });

    const hasContent = computed(() => {
      if (props.contentVisible !== undefined) return props.contentVisible;
      return false;
    });

    const hasFooter = computed(() => {
      if (props.footerVisible !== undefined) return props.footerVisible;

      return hasFooterButtons.value;
    });

    const template = computed(() => {
      if (props.template) return props.template;
      const visual = _sectionData.value?.visual;
      if (visual?.vis) {
        if (visual.pos && visual.pos !== MODULE_DEFAULT) {
          return 'visual-' + visual.pos.toLowerCase();
        }
      }
      return 'default';
    });

    const templateDesktop = computed(() => {
      if (props.template) return props.template;
      const visual = _sectionData.value?.visual;
      if (visual?.vis || visual?.visD) {
        if (visual.posD && visual.posD !== MODULE_DEFAULT) {
          return 'visual-' + visual.posD.toLowerCase();
        }
      }
      return 'default';
    });

    const templateClasses = computed(() => {
      const cls = [];

      cls.push('tpl-' + template.value);
      cls.push('tpl-d-' + templateDesktop.value);
      cls.push('wrapper-' + props.wrapperContainer);
      if (hasAboveVisual.value) cls.push('has-above-visual');
      if (hasVisual.value) cls.push('has-visual');
      if (hasHeader.value) cls.push('has-header');
      if (hasContent.value) cls.push('has-content');
      if (hasFooter.value) cls.push('has-footer');

      if (props.visualOut) cls.push('visual-out');
      if (props.visualOutD) cls.push('visual-d-out');
      if (props.contentOut) cls.push('content-out');
      if (props.contentOutD) cls.push('content-d-out');

      if (_sectionData.value?.visual?.ratioD) {
        if (
          ['3:4', '3:5', '4:5', '5:6', '9:16'].includes(
            _sectionData.value.visual.ratioD
          )
        ) {
          cls.push('d-visual-vertical');
        }
      }

      cls.push(is2colsOnMobile.value ? 'tpl-2cols' : 'tpl-1col');
      cls.push(is2colsOnDesktop.value ? 'tpl-d-2cols' : 'tpl-d-1col');

      return cls;
    });

    const is2colsOnMobile = computed(() => {
      return !['default', 'visual-top', 'visual-bottom'].includes(
        template.value
      );
    });

    const is2colsOnDesktop = computed(() => {
      return !['default', 'visual-top', 'visual-bottom'].includes(
        templateDesktop.value
      );
    });

    const maxGridCols = 12;

    const visualCols = computed(() => {
      if (is2colsOnMobile.value) {
        return props.visualCols ? props.visualCols : 6;
      }
      return maxGridCols;
    });

    const visualColsD = computed(() => {
      if (is2colsOnDesktop.value) {
        return props.visualColsD ? +props.visualColsD : 6;
      }
      return maxGridCols;
    });

    const contentCols = computed(() => {
      if (props.contentCols) return props.contentCols;
      const cols = maxGridCols - visualCols.value;
      return cols || maxGridCols;
    });

    const contentColsD = computed(() => {
      if (props.contentColsD) return props.contentColsD;
      const cols = maxGridCols - visualColsD.value;
      return cols || maxGridCols;
    });

    const visualClasses = computed(() => {
      const cls = [];
      if (props.visualClass) cls.push(props.visualClass);
      cls.push('s:col-span-' + visualCols.value);
      cls.push('m-and-l:col-span-' + visualColsD.value);
      return cls;
    });

    const aboveVisualClasses = computed(() => {
      const cls = [];
      cls.push('s:col-span-' + contentCols.value);
      cls.push('m-and-l:col-span-' + contentColsD.value);
      if (props.visualClass) cls.push(props.aboveVisualClass);
      return cls;
    });

    const headerClasses = computed(() => {
      const cls = [];
      cls.push('s:col-span-' + contentCols.value);
      cls.push('m-and-l:col-span-' + contentColsD.value);
      if (props.headerClass) cls.push(props.headerClass);
      return cls;
    });

    const contentClasses = computed(() => {
      const cls = [];
      cls.push('s:col-span-' + contentCols.value);
      cls.push('m-and-l:col-span-' + contentColsD.value);
      if (props.contentClass) cls.push(props.contentClass);
      return cls;
    });

    const footerClasses = computed(() => {
      const cls = [];
      cls.push('s:col-span-' + contentCols.value);
      cls.push('m-and-l:col-span-' + contentColsD.value);
      if (props.footerClass) cls.push(props.footerClass);
      return cls;
    });

    const buttons = computed(() => {
      return (
        _sectionData.value?.buttonCollection?.items?.reduce((acc, item) => {
          if (item && item.__typename?.toLowerCase() === 'button') {
            const buttonData = getButtonDataFunction(item);
            if (buttonData && buttonData.visible) {
              acc.push(buttonData);
            }
          }
          return acc;
        }, []) || []
      );
    });

    return {
      _sectionData,
      templateClasses,
      visualClasses,
      aboveVisualClasses,
      headerClasses,
      contentClasses,
      footerClasses,
      getVisualData,
      hasAboveVisual,
      hasVisual,
      hasHeader,
      hasContent,
      hasFooter,
      buttons
    };
  }
});
</script>

<style lang="scss" scoped>
.vst {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto;
  grid-row-gap: 30px;
  grid-column-gap: 20px;

  // General Default settings
  & > div {
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    width: 100%;
  }

  &.wrapper-lg {
    max-width: 1240px;
  }

  .vst__above-visual {
    grid-area: aboveVisual;
    align-self: center;
  }
  .vst__visual {
    grid-area: visual;
    align-self: start;
    min-width: 100%;
  }
  .vst__header {
    grid-area: header;
    align-self: center;
  }
  .vst__content {
    grid-area: content;
    align-self: center;
  }
  .vst__footer {
    grid-area: footer;
    align-self: center;
  }

  // Default areas for 1col (same for Mobile and Desktop)
  &.tpl-1col,
  &.tpl-d-1col {
    &.has-visual {
      grid-template-areas: 'visual';
    }
    &.has-header {
      grid-template-areas: 'header';
    }
    &.has-content {
      grid-template-areas: 'content';
    }
    &.has-footer {
      grid-template-areas: 'footer';
    }
    &.has-header.has-content {
      grid-template-areas: 'header' 'content';
    }
    &.has-header.has-footer {
      grid-template-areas: 'header' 'footer';
    }
    &.has-content.has-footer {
      grid-template-areas: 'content' 'footer';
    }
    &.has-header.has-content.has-footer {
      grid-template-areas: 'header' 'content' 'footer';
    }
    &.has-visual.has-header {
      grid-template-areas: 'visual' 'header';
    }
    &.has-visual.has-content {
      grid-template-areas: 'visual' 'content';
    }
    &.has-visual.has-footer {
      grid-template-areas: 'visual' 'footer';
    }
    &.has-visual.has-header.has-content {
      grid-template-areas: 'visual' 'header' 'content';
    }
    &.has-visual.has-content.has-footer {
      grid-template-areas: 'visual' 'content' 'footer';
    }
    &.has-visual.has-header.has-footer {
      grid-template-areas: 'visual' 'header' 'footer';
    }
    &.has-visual.has-footer.has-content.has-footer {
      grid-template-areas: 'visual' 'header' 'content' 'footer';
    }
  }

  // Areas for 2cols for Mobile
  @include for-screen-s {
    &.has-visual.tpl-2cols {
      grid-template-rows: auto;

      &.has-content {
        grid-template-areas: 'visual content';
      }
      &.has-header {
        grid-template-areas: 'visual header';
      }
      &.has-header.has-content {
        grid-template-areas: 'visual header' 'visual content';
        .vst__header {
          align-self: end;
        }
        .vst__content {
          align-self: start;
        }
      }
      &.has-content.has-footer {
        grid-template-areas: 'visual content' 'visual footer';
        .vst__content {
          align-self: end;
        }
        .vst__footer {
          align-self: start;
        }
      }

      &.has-header.has-footer {
        grid-template-areas: 'visual header' 'visual footer';
        .vst__header {
          align-self: end;
        }
        .vst__footer {
          align-self: start;
        }
      }
      &.has-header.has-content.has-footer {
        grid-template-areas: 'visual header' 'visual content' 'visual footer';
        grid-template-rows: auto minmax(min-content, 0px) auto;
        .vst__header {
          align-self: end;
        }
        .vst__content {
          align-self: center;
        }
        .vst__footer {
          align-self: start;
        }
      }

      &.tpl-visual-right {
        .vst__visual {
          order: 2;
        }
      }
    }

    &.tpl-2cols > div {
      margin-left: unset;
      margin-right: auto;
    }
  }

  // Areas for 2cols for Desktop
  @include for-screen-m-and-l {
    &.has-visual.tpl-d-2cols {
      grid-template-rows: auto;
      align-items: center;
      grid-row-gap: 30px;
      grid-column-gap: 60px;

      &.has-content {
        grid-template-areas: 'visual content';
      }
      &.has-header {
        grid-template-areas: 'visual header';
      }
      &.has-header.has-content {
        grid-template-areas: 'visual header' 'visual content';
        .vst__header {
          align-self: end;
        }
        .vst__content {
          align-self: start;
        }
      }
      &.has-content.has-footer {
        grid-template-areas: 'visual content' 'visual footer';
        .vst__content {
          align-self: end;
        }
        .vst__footer {
          align-self: start;
        }
      }

      &.has-header.has-footer {
        grid-template-areas: 'visual header' 'visual footer';
        .vst__header {
          align-self: end;
        }
        .vst__footer {
          align-self: start;
        }
      }
      &.has-header.has-content.has-footer {
        grid-template-areas: 'visual header' 'visual content' 'visual footer';
        grid-template-rows: auto minmax(min-content, 0px) auto;
        .vst__header {
          align-self: end;
        }
        .vst__content {
          align-self: center;
        }
        .vst__footer {
          align-self: start;
        }
      }

      &.tpl-d-visual-right {
        .vst__visual {
          order: 2;
        }
      }
    }

    &.tpl-d-2cols > div {
      margin-left: unset;
      margin-right: auto;
    }
  }

  // Content/Visual Out Logic for 1col for Mobile and Desktop (same)
  &.tpl-1col,
  &.tpl-2cols {
    @include for-screen-s {
      &.visual-out {
        .vst__visual-inner {
          @apply mx-section-neg;
        }
      }
      &.content-out {
        .vst__content-inner {
          @apply mx-section-neg;
        }
      }
    }
    @include for-screen-m-and-l {
      &.visual-d-out {
        .vst__visual-inner {
          @apply mx-section-neg;
        }
      }
      &.content-d-out {
        .vst__content-inner {
          @apply mx-section-neg;
        }
      }
    }
  }

  @include for-screen-s {
    &.visual-out.tpl-2cols {
      .vst__visual-inner {
        margin-left: var(--spacer-section-x-neg);
      }
    }
    &.content-out.tpl-2cols {
      .vst__content-inner {
        margin-right: var(--spacer-section-x-neg);
      }
    }
  }
  @include for-screen-m-and-l {
    &.visual-d-out.tpl-d-2cols {
      .vst__visual-inner {
        margin-left: var(--spacer-section-x-neg);
      }
    }
    &.content-d-out.tpl-d-2cols {
      .vst__content-inner {
        margin-right: var(--spacer-section-x-neg);
      }
    }
  }

  // Other overrides
  .module__heading {
    margin-bottom: 10px;
    @include for-screen-m-and-l {
      margin-bottom: 15px;
    }
    ::v-deep {
      h1,
      h2 {
        @apply title-h1;
      }

      h3 {
        @apply title-h3em;
      }
    }
  }

  .module__buttons {
    margin-top: 25px;
    @include for-screen-m-and-l {
      margin-top: 30px;
    }

    &.in-footer {
      margin-top: 0;
      @include for-screen-m-and-l {
        margin-top: 0;
      }
    }
  }

  ::v-deep {
    .vaimo-truncated-text__text {
      display: flex;
      flex-flow: column;
      p:not(:last-child) {
        margin-bottom: 1em;
      }
    }
  }
}
</style>
