













































































import type { PropType } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import {
  computed,
  defineComponent,
  ssrRef,
  useFetch
} from '@nuxtjs/composition-api';
import VaimoBanner from 'atoms/VaimoBanner.vue';
import { getButtonsWithSkuLinks } from 'templates/sections/EditoProductPush/getters/editoProductPushGetters';

import { useDeclination } from '@/diptyqueTheme/composable/useDeclination';
import { useScreenSize } from '~/diptyqueTheme/composable';
import { useVisual } from '~/diptyqueTheme/composable/useVisual';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';
import type {
  EditoProductPushModel,
  FlexibleSectionModel
} from '~/diptyqueTheme/types/contentful';
// eslint-disable-next-line no-duplicate-imports
import { VisualPosition } from '~/diptyqueTheme/types/contentful';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import type { Product } from '~/modules/catalog/product/types';

export default defineComponent({
  name: 'EditoProductPush',
  components: {
    VaimoBanner,
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    VaimoBadge: () => import('atoms/VaimoBadge.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object as PropType<EditoProductPushModel>
    },
    pageName: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const { getBannerOrVisualData } = useDeclination(props.sectionData);

    const { getProductList } = useProduct();

    const product = ssrRef<Product | null>(null);

    const getProductInfo = async (sku: string): Promise<void> => {
      const data = await getProductList({
        filter: {
          sku: {
            in: [sku]
          }
        },
        pageSize: 1
      });
      product.value = data?.items?.[0] as Product;
    };

    const { isDesktop } = useScreenSize();

    const sku = computed(() => props.sectionData?.sku);

    useFetch(() => sku.value && getProductInfo(sku.value));

    const productPrice = customProductGetters.getProductCardPrice(
      product.value
    );

    const buttons = computed(() =>
      getButtonsWithSkuLinks(props.sectionData, sku.value)
    );

    const { ratio, position } = useVisual(props.sectionData?.visualEntry);

    const isWideVisual = computed(() => {
      if (!isDesktop.value) {
        return false;
      }

      return ratio.value === '16:9';
    });

    const reverseContent = computed(() => {
      return position.value === VisualPosition.RIGHT;
    });

    return {
      data: props.sectionData,
      reverseContent,
      isWideVisual,
      buttons,
      product,
      productPrice,
      getBannerOrVisualData,
      isDesktop
    };
  }
});
