

























import {
  defineComponent,
  computed,
  ref,
  watch,
  useFetch,
  ssrRef
} from '@nuxtjs/composition-api';
import { useProduct, useImage } from '~/composables';
import { Product } from '~/modules/catalog/product/types';
import { useAddToCart } from '~/helpers/cart/addToCart';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import VaimoProductCard from '~/diptyqueTheme/components/organisms/VaimoProductCard.vue';
import VaimoSlider from '~/diptyqueTheme/components/molecules/VaimoSlider.vue';
import VaimoGrid from '~/diptyqueTheme/components/molecules/VaimoGrid.vue';
import groupTypes from '~/diptyqueTheme/config/productGroupTypes';
import { SfLoader } from '@storefront-ui/vue';
import VaimoQuickSearchProduct from './VaimoQuickSearchProduct.vue';

export default defineComponent({
  name: 'VaimoQuickSearchProducts',
  components: {
    VaimoProductCard,
    VaimoSlider,
    VaimoGrid,
    SfLoader,
    VaimoQuickSearchProduct
  },
  props: {
    uniqueKey: {
      required: false,
      type: String,
      default: 'VaimoQuickSearchProducts'
    },
    skuList: {
      required: false,
      type: Array,
      default: []
    },
    skuListType: {
      required: false,
      type: String,
      default: 'listOfSku'
    },
    nostoId: {
      required: false,
      type: String
    },
    display: {
      required: false,
      type: String,
      default: 'slider',
      validator: (val: string) => ['slider', 'grid'].includes(val)
    },
    wrapperProps: {
      required: false,
      type: Object,
      default: () => ({})
    },
    pageSize: {
      type: Number,
      required: false,
      default: 20
    },
    loadingType: {
      required: false,
      type: String,
      validator: (val: string) => ['spinner'].includes(val)
    },
    gridColsMobile: {
      type: Number,
      required: false,
      default: 2
    },
    gridColsDesktop: {
      type: Number,
      required: false,
      default: 4
    }
  },
  setup(props) {
    const { getMagentoImage } = useImage();
    const { getProductList, loading: productsLoading } = useProduct();
    const {
      addItemToCart,
      isInCart,
      loading: addToCartLoading
    } = useAddToCart();

    const SKUs = computed(() => props.skuList);
    const SKUsType = computed(() => props.skuListType);
    const nostoSlotId = computed(() => props.nostoId);

    const getProducts = async () => {
      const loadedSKUs = products.value.map((p) => p.sku).sort();
      if (Array.isArray(SKUs.value)) {
        if (loadedSKUs[0] === SKUs.value[0]) return;
      }

      if (SKUs.value?.length) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore;
        await getProductsBySku(SKUs.value, SKUsType.value);
      }
    };

    const products = ssrRef([], 'VaimoProducts' + props.uniqueKey);

    const getProductsBySku = async (sku_array: string[], type: string) => {
      const filterType = type === groupTypes.NOSTO ? '_id' : 'sku';
      try {
        const data = await getProductList({
          filter: {
            [filterType]: {
              in: sku_array
            }
          },
          pageSize: props.pageSize
        });
        products.value = data?.items.sort((a,b) => sku_array.indexOf(a.sku) - sku_array.indexOf(b.sku));
      } catch (e) {
        console.error(e);
      }
    };

    watch(SKUs, async () => {
      await getProducts();
    });

    useFetch(async () => {
      await getProducts();
    });

    const getProductLink = (product: Product): string => {
      return `${productGetters.getSlug(product, product?.categories?.[0])}`;
    };

    const addToCartLoadingSKU = ref();
    const addToCart = (item) => {
      addToCartLoadingSKU.value = item.product.sku;
      addItemToCart(item);
    };

    const getAddToCartLoadingFlag = (product) => {
      return (
        addToCartLoading.value && addToCartLoadingSKU.value === product.sku
      );
    };

    const wrapperComponent = computed(() => {
      if (props.display === 'slider') return 'VaimoSlider';
      if (props.display === 'grid') return 'VaimoGrid';
    });

    const wrapperDisplayProps = computed(() => {
      return props.wrapperProps[props.display] || {};
    });

    const itemsLength = computed(() => {
      return products.value?.length || 0;
    });

    const wrapperClasses = computed(() => {
      if (props.display === 'grid') {
        return [
          'grid-cols-' + props.gridColsMobile,
          'm-and-l:grid-cols-' + props.gridColsDesktop
        ];
      }
      return '';
    });

    return {
      productGetters,
      products,
      productsLoading,
      getProductLink,
      getMagentoImage,
      addToCart,
      isInCart,
      addToCartLoading,
      addToCartLoadingSKU,
      getAddToCartLoadingFlag,
      wrapperComponent,
      wrapperDisplayProps,
      wrapperClasses,
      itemsLength,
      VaimoQuickSearchProduct
    };
  }
});
