


















































































import {
  computed,
  defineComponent,
  PropType,
  useContext,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { SfNotification } from '@storefront-ui/vue';
import VaimoButton from 'atoms/VaimoButton.vue';

import { useUiNotification } from '~/composables';
import { useUiState } from '~/composables/useUiState';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useProductStore } from '~/modules/catalog/product/stores/product';

interface AreaInterface {
  area: 'top' | 'default';
}

export default defineComponent({
  name: 'AddToCartNotification',
  components: {
    VaimoButton,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    SfNotification
  },
  props: {
    area: {
      required: false,
      type: String as PropType<AreaInterface['area']>,
      default: 'default' as AreaInterface['area']
    }
  },
  setup(props) {
    const {
      app: { $gtm },
      localePath
    } = useContext();
    const router = useRouter();
    const { notifications, isVisible } = useUiNotification();
    const getNotificationByArea = computed<object[]>(() => {
      return notifications.value.filter((notification) => {
        if (!notification.area) {
          notification.area = 'default';
        }

        return notification.area === props.area;
      });
    });

    const getProductPrice = (product): number => {
      if (product?.dynamic_price) {
        return product?.dynamic_price;
      }
      return productGetters.getPrice(product).regular || 0;
    };

    const productStore = useProductStore();

    const isEngravingInfo = computed(() => {
      return productStore.isEngravingInfoProcessed;
    });

    const getWrapperClasses = computed<(string | object)[]>(() => {
      return [
        {
          active: getNotificationByArea.value.length > 0
        },
        'area-' + props.area
      ];
    });

    const getProductImage = (notification): string | undefined => {
      if (notification.additional_info.product?.thumbnail?.url) {
        return notification.additional_info.product.thumbnail.url;
      } else if (notification.additional_info.product?.small_image?.url) {
        return notification.additional_info.product?.small_image?.url;
      }
    };

    const proceedToCheckout = (close: () => void): void => {
      router.push(localePath('/cart'));
      close();
      window.scrollTo(0, 0);
    };

    const onClickOutSide = (close: () => void): void => {
      if (!isVisible.value) {
        return;
      }
      close();
    };

    const visibilityState = computed(() => {
      return isVisible.value;
    });

    const { isEngravingInfoOpen, hideEngravingInfo } = useUiState();
    watch(
      () => visibilityState.value,
      (newValue: boolean) => {
        if (
          isEngravingInfoOpen &&
          newValue === false &&
          productStore.isEngravingInfoProcessed === true
        ) {
          hideEngravingInfo();
          productStore.isEngravingInfoProcessed = false;
        }
      }
    );

    const close = (notification: () => void): void => {
      $gtm.push({
        event: 'click_close_popin'
      });
      if (typeof notification === 'function') {
        notification();
      }
    };

    return {
      notifications,
      onClickOutSide,
      getWrapperClasses,
      getNotificationByArea,
      close,
      validateFraction,
      getProductImage,
      getProductPrice,
      isEngravingInfo,
      proceedToCheckout
    };
  }
});
