

















import {
  defineComponent,
  onMounted,
  ref,
  useContext,
  useRouter,
  watch
} from '@nuxtjs/composition-api';

import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { useAvailableShippingMethodsStore } from '~/diptyqueTheme/stores/checkout';
import useCart from '~/modules/checkout/composables/useCart';
import { lsGet } from '~/diptyqueTheme/composable/useLocalStorage';

export default defineComponent({
  name: 'ShippingStep',
  components: {
    VaimoShippingProvider: () =>
      import(
        '~/diptyqueTheme/modules/checkout/components/VaimoShippingProvider.vue'
      ),
    OrderSummaryWithTopBar: () =>
      import(
        '~/diptyqueTheme/modules/checkout/components/OrderSummaryWithTopBar.vue'
      ),
    VaimoLoader: () =>
      import('~/diptyqueTheme/components/atoms/VaimoLoader.vue'),
    EditEmail: () =>
      import('~/diptyqueTheme/modules/checkout/components/EditEmail.vue'),
    EditAddress: () =>
      import('~/diptyqueTheme/modules/checkout/components/EditAddress.vue')
  },
  scrollToTop: true,
  setup() {
    const router = useRouter();
    const shipping = ref('');
    const { app } = useContext();
    const { cart } = useCart();
    const { getAddShippingInfoDetails } = useGoogleTagManager();
    const shippingMethodsStore = useAvailableShippingMethodsStore();
    const isSubmitting = ref(false);

    const onSavingAndCallLoader = (value: boolean) => {
      isSubmitting.value = value;
    }

    const onShippingMethodSubmit = () => {
      // @TODO: remove when setShippingMethod request will work faster then 3 seconds. For now this adds up with another "waiting"
      // to make sure shipping nethod is set on cart, before order placement is happening.
      isSubmitting.value = true;
      setTimeout(function () {
        app.$gtm.push(getAddShippingInfoDetails(cart.value));
        router.push(app.localeRoute({ name: 'payment' }));
        isSubmitting.value = false;
      }, 1500); // approximate time for the half of shipping method selection request on most stores. Another half is "covered" by payment methods loading.
    };

    watch(
      () => shippingMethodsStore.isPending,
      (isPending) => {
        if (!isPending) {
          manipulateWithLocalStorage();
        }
      }
    );

    const sortAndStringifyData = (data: object): string => {
      return JSON.stringify(data).split('').sort().join('');
    };

    const manipulateWithLocalStorage = (): void => {
      const vsfCheckoutData = lsGet('vsf-checkout');
      // todo: figure out what's is going on here
      shipping.value = sortAndStringifyData(
        vsfCheckoutData['shipping-address']
      );
    };

    onMounted(async () => {
      window.scroll(0, 0);
    });

    return {
      onShippingMethodSubmit,
      onSavingAndCallLoader,
      isSubmitting
    };
  }
});
