



























import {
  computed,
  defineComponent,
  useContext,
  watch
} from '@nuxtjs/composition-api';

import { useUiState } from '~/composables/useUiState';
import { useScreenSize } from '~/diptyqueTheme/composable';
import { toggleZendeskWidget } from '~/diptyqueTheme/helpers/toggleZendeskWidget';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';

export default defineComponent({
  name: 'CategoryCollectionSidebar',
  components: {
    VaimoSidebar: () => import('organisms/VaimoSidebar.vue'),
    VaimoProducts: () => import('organisms/VaimoProducts.vue')
  },
  props: {
    productsSkus: {
      required: true,
      type: Array
    },
    productsSkusUnsorted: {
      required: false,
      type: Array,
      default: () => []
    },
    title: {
      required: true,
      type: String
    }
  },
  setup(props) {
    const { isCollectionSidebarOpen, toggleCollectionSidebar } = useUiState();
    const { app } = useContext();
    const categoryStore = useCategoryStore();
    const { isMobile } = useScreenSize();

    watch(
      () => isCollectionSidebarOpen.value,
      (newValue) => {
        categoryStore.isCollectionSidebarOpen = newValue;
        if (isMobile.value) {
          toggleZendeskWidget(newValue);
        }
      }
    );

    const getTitle = computed(() => {
      return app.i18n.t('The complete {category_name} collection', {
        category_name: props.title
      });
    });

    return {
      getTitle,
      isCollectionSidebarOpen,
      toggleCollectionSidebar
    };
  }
});
