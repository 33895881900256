<!-- New Component Name: Categories Navigation, CategoriesNavigation -->

<template>
  <div class="block-internal-navigation text-center px-md">
    <LoadWhenVisible>
      <VaimoHeading
        v-if="sectionData.heading"
        :heading="sectionData.heading"
        :heading-link="sectionData.headingLink"
      />
    </LoadWhenVisible>
    <LoadWhenVisible>
      <VaimoHeading
        v-if="sectionData.subHeading"
        :heading="sectionData.subHeading"
        heading-level="h3"
        class="uppercase module__subheading"
      />
    </LoadWhenVisible>

    <div
      class="module__items grid grid-cols-2 desktop:grid-cols-4 gap-md mt-3md"
    >
      <div v-for="item in items" :key="item.sys.id">
        <LoadWhenVisible>
          <VaimoBanner
            v-if="item.image"
            :src="item.image.url"
            :link="normalizeLink(item.link)"
            :title="item.title"
            source="contentful"
            :max-optimized-width="482"
            class="mb-sm"
          />
        </LoadWhenVisible>
        <div>
          <LoadWhenVisible>
            <VaimoButton
              v-if="item.link && item.title"
              variant="edito link"
              :link="item.link"
              class="module__item-link"
            >
              {{ item.title }}
            </VaimoButton>
          </LoadWhenVisible>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';

import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useLink } from '~/diptyqueTheme/composable';

export default defineComponent({
  name: 'BlockInternalNavigation',
  components: {
    LoadWhenVisible,
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { normalizeLink } = useLink();
    const items = computed(() => {
      if (!props.sectionData.itemsCollection) return [];
      return props.sectionData.itemsCollection?.items.filter((i) => i);
    });

    return {
      items,
      normalizeLink
    };
  }
});
</script>

<style scoped lang="scss">
.block-internal-navigation {
  .module__items {
    text-align: left;
    @include for-screen-m-and-l {
      padding-left: calc(100% / 12);
      padding-right: calc(100% / 12);
    }
  }

  ::v-deep {
    .module__subheading h3 {
      font-size: 14px;
      font-weight: 300;
      @include for-screen-m-and-l {
        font-size: 16px;
      }
    }
  }
}
</style>
