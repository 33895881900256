









































































































































































































































































































































































































































































































































































































































import { Logger } from '~/helpers/logger';
import {
  computed,
  ComputedRef,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  useContext,
  useRouter,
  watch
} from '@nuxtjs/composition-api';
import { SfCheckbox, SfHeading, SfInput, SfSelect } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import {digits, max, min, required} from 'vee-validate/dist/rules';
import {charsRegex, doubleQuotesRegex, hiraganaRegex, kanaRegex, katakanaRegex, nameRegex, phoneRegex, phoneRegexJP, postcodeJP} from "~/diptyqueTheme/helpers/regexes";
import {useConfig, useCountrySearch, useStore} from '~/composables';
import VaimoPhone from 'organisms/VaimoPhone.vue';

import {
  addressFromApiToForm,
  addressFromCustomerToBillingCart,
  BillingAddress,
  CheckoutAddressForm,
  findUserAddressIdenticalToSavedCartAddress,
  formatAddressReturnToData,
  getInitialCheckoutAddressForm
} from '~/diptyqueTheme/helpers/checkout/address';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import useBilling, {
  BillingDetails
} from '~/modules/checkout/composables/useBilling';
import useAddresses from '~/modules/customer/composables/useAddresses';
import { useUser } from '~/modules/customer/composables/useUser';
import { useUserAddress } from '~/modules/customer/composables/useUserAddress';
import addressGetter from '~/modules/customer/getters/addressGetter';
import userBillingGetters from '~/modules/customer/getters/userBillingGetters';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { lsGet, lsSet, lsRemove } from '~/diptyqueTheme/composable/useLocalStorage';
import { usePostcode } from '~/diptyqueTheme/composable/usePostcode';
import type {
  Country,
  Customer,
  CustomerAddress,
  ShippingCartAddress
} from '~/modules/GraphQL/types';
import { TransformedCountryInterface } from '~/diptyqueTheme/stores/countries/types';
import {useRules} from "~/diptyqueTheme/composable/useRules";
import { useZip2Address } from '~/diptyqueTheme/composable/useZip2Address';

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('min', {
  ...min,
  message: 'The field should have at least {length} characters'
});
extend('max', {
  ...max,
  message: '30 characters maximum'
});
extend('digits', {
  ...digits,
  message: 'Please provide a valid phone number'
});
extend('alpha', {
  validate(value) {
    return {
      valid: /^[a-zA-ZÀ-Ÿ0-9\s., &#/!\'-]+$/.test(value)
    };
  },
  message: 'Use only alphabetic letters'
});
extend('name', {
  message: 'Use only alphabetic letters',
  validate(value) {
    return {
      valid: nameRegex.test(value)
    };
  }
});
extend('phone', {
  validate(value) {
    return {
      valid: phoneRegex.test(value.replace(/\D/g, ''))
    };
  }
});
extend('chars', {
  message: 'Only these characters',
  validate(value) {
    return {
      valid: charsRegex.test(value)
    };
  }
});

extend('doubleQuotes', {
  message: '" is a forbidden character',
  validate(value) {
    return {
      valid: doubleQuotesRegex.test(value)
    };
  }
});
extend('validate-katakana', {
  message: 'Please enter in the full-width katakana.',
  validate(value) {
    return {
      valid: katakanaRegex.test(value)
    };
  }
});

extend('validate-hiragana', {
  message: 'Please use Hiragana only in this field.',
  validate(value) {
    return {
      valid: hiraganaRegex.test(value)
    };
  }
});

extend('validate-kana', {
  message: 'Please use full width kana only in this field.',
  validate(value) {
    return {
      valid: kanaRegex.test(value)
    };
  }
});

extend('phone-jp', {
  message: 'Please enter only half-width numbers without hyphens and up to 11 digits.',
  validate(value) {
    return {
      valid: phoneRegexJP.test(value.replace(/ /g, ''))
    };
  }
});

extend('postcode-jp', {
  message: 'Provided Zip/Postal Code seems to be invalid.',
  validate(value) {
    return {
      valid: postcodeJP.test(value)
    };
  }
});

export default defineComponent({
  name: 'BillingStep',
  components: {
    SfHeading,
    SfInput,
    SfCheckbox,
    SfSelect,
    VaimoPhone,
    ValidationProvider,
    ValidationObserver,
    VaimoAddresses: () => import('organisms/VaimoAddresses.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  props: {
    shippingDetails: {
      required: false,
      type: Object,
      // eslint-disable-next-line
      default: {}
    },
    availableRegions: {
      required: false,
      type: Array,
      default: () => []
    },
    isUsOrCaStore: Boolean,
    isStateFieldShow: Boolean,
    selectedShippingAddressId: {
      required: false,
      type: Number
    }
  },
  emits: ['onBillingPhoneChanged'],
  setup(props, { emit }) {
    const router = useRouter();
    const { app } = useContext();
    const shippingDetails = ref<ShippingCartAddress | null>(null);
    const userBilling = ref<Customer | null>(null);
    const chosenBillingAddress = ref(null);
    const countryStore = useCountriesStore();
    const { getRules } = useRules();
    const { isZhHkStore, isJpStore, getNameKanaRules } = useStore();
    const { searchZipCode, zip2AddressData } = useZip2Address();
    const { config } = useConfig();
    const { save, load: loadBilling, loading } = useBilling();
    const { load: loadUserBilling, setDefaultAddress } = useUserAddress();
    const { search: searchCountry } = useCountrySearch();
    const billingBoundToLocalStorage = ref<BillingAddress | null>(null);
    const regionList = ref([]);
    const countries = ref<TransformedCountryInterface[]>([]);
    const country = ref<Country | null>(null);
    const isSubmitting = ref(false);
    const storeCode = computed(() => app.i18n.localeProperties.code);
    const isBillingValid = ref(false);
    const customerStore = useCustomerStore();
    const { setDefaultPostCode, isHkMoCode } = usePostcode();

    const { save: saveUserAddress, load: loadUserAddresses } = useAddresses();

    const { isAuthenticated, user } = useUser();
    let oldBilling: CheckoutAddressForm | null = null;
    const sameAsShipping = ref(true);
    const billingDetails = ref<CheckoutAddressForm>(
      getInitialCheckoutAddressForm()
    );
    const isDeStore = computed(
      () => app.i18n.localeProperties.code === 'de_eu'
    );
    const currentAddressId = ref<number | null>(null);
    const setAsDefault = ref(false);
    const isFormSubmitted = ref(false);
    const isAddNewAddressFormVisible = ref(!isAuthenticated.value);
    const isStateFieldVisible = ref(false);
    const addresses = computed(
      () =>
        customerStore.user?.addresses.filter((address) =>
          typeof countriesList.value.find(
            (country) => country?.id === address.country_code
          ) !== 'undefined'
            ? true
            : false
        ) ?? []
    );
    const billingInfoBoundToCart = ref<BillingAddress | null>(null);
    const createdAddressId = ref(null);
    const isPhoneValid = ref(true);

    const isPostcodeFieldVisible = computed(() => {
      return (
        billingDetails.value?.country_code &&
        !isHkMoCode(billingDetails.value?.country_code)
      );
    });
    const phoneKey = ref(0);

    const canMoveForward = computed(
      () =>
        !loading.value &&
        billingDetails.value &&
        Object.keys(billingDetails.value).length > 0
    );

    const hasSavedBillingAddress = computed(() => {
      if (!isAuthenticated.value || !userBilling.value) {
        return false;
      }
      return addresses.value.length > 0;
    });

    const countriesList = computed(() => countries.value);
    const regionInformation = computed(() =>
      addressGetter.regionList(country.value)
    );

    const shippingAddress = computed(() => props.shippingDetails);

    watch(shippingAddress, () => {
      if (sameAsShipping.value) {
        isBillingValid.value = true;
        billingDetails.value = shippingAddress.value as CheckoutAddressForm;
      }
    });

    // Set Default Postcode if Address Country is HongKong or Macao
    watch(
      () => billingDetails.value.country_code,
      (newCountryCode) => {
        if (isHkMoCode(newCountryCode)) {
          // 'force' is used to overwrite the incorrect one
          // which might be copied from Shipping Address before
          setDefaultPostCode(billingDetails.value, true);
        }
        isStateFieldVisible.value =
          newCountryCode === 'US' ||
          newCountryCode === 'CA' ||
          newCountryCode === 'JP';
      }
    );

    const preSelectRegion = () => {
      if (
        regionList.value.length &&
        isStateFieldVisible.value &&
        typeof billingDetails.value.region === 'string'
      ) {
        const regionObject = regionList.value.find(
          (obj) =>
            obj['region_code'] === billingDetails.value.region ||
            obj['name'] === billingDetails.value.region
        );
        billingDetails.value.region = regionObject;
      }
    };

    const nameKanaRules = getNameKanaRules();
    const zip2Address = async () => {
      if (postcodeJP.test(billingDetails.value.postcode)) {
        await searchZipCode(billingDetails.value.postcode);
        if (Object.keys(zip2AddressData.value).length) {
          // @ts-ignore
          let selectedRegion = regionList.value.find(item => item.name.includes(zip2AddressData.value.region));
          billingDetails.value.region = selectedRegion;
          billingDetails.value.region_id = selectedRegion?.region_id ?? null;
          // @ts-ignore
          billingDetails.value.city = zip2AddressData.value.city;
        }
      }
    }

    const handleAddressSubmit = async () => {
      const addressId = currentAddressId.value;
      let chosenAddr = null;
      isSubmitting.value = true;
      if (sameAsShipping.value) {
        isBillingValid.value = true;
        billingDetails.value = shippingAddress.value as CheckoutAddressForm;
        if (shippingAddress.value.region && typeof shippingAddress.value.region === 'object') {
          const region = shippingAddress.value.region['region'];
          const regionId = shippingAddress.value.region['region_id'];
          /* RegionId can be undefined, so condition is needed, cause it leads to problems
          with State field filling as computed variables from Billing and Shipping are conflicting */
          billingDetails.value.region = region;
          if (regionId) {
            billingDetails.value.region_id = regionId;
          }
        }
      }

      if (isAuthenticated.value && addresses.value && addressId) {
        chosenAddr = addresses.value.find((obj) => obj.id === addressId);
      }

      //added to prevent saving shipping address again on BE side
      let billingDetailsData = {
        billingDetails: addressFromCustomerToBillingCart({
          ...billingDetails.value,
          customerAddressId: addressId === null ? null : String(addressId),
          sameAsShipping: false,
          save_in_address_book: false
        })
      };

      if (
        isAuthenticated.value &&
        chosenAddr !== null &&
        !sameAsShipping.value
      ) {
        billingDetailsData = {
          billingDetails: addressFromCustomerToBillingCart({
            ...chosenAddr,
            customerAddressId: addressId === null ? null : String(addressId),
            sameAsShipping: sameAsShipping.value,
            save_in_address_book: false
          })
        };

        chosenBillingAddress.value = billingDetailsData;
      }

      if (
        isAuthenticated.value &&
        sameAsShipping.value &&
        props.selectedShippingAddressId
      ) {
        billingDetailsData.billingDetails.customerAddressId = props.selectedShippingAddressId;
      }

      billingDetailsData.billingDetails = setDefaultPostCode(
        billingDetailsData?.billingDetails
      );

      if (
        billingDetailsData?.billingDetails?.region &&
        typeof billingDetailsData?.billingDetails?.region === 'object' &&
        !billingDetailsData?.billingDetails?.region?.region
      ) {
        billingDetailsData.billingDetails.region = null;
      }

      await save(billingDetailsData);
      app.$bus.$emit('BillingSave');

      if (isAuthenticated.value && addressId !== null && setAsDefault.value) {
        const [chosenAddress] = userBillingGetters.getAddresses(
          userBilling.value,
          { id: addressId }
        );

        chosenAddress.default_billing = setAsDefault.value;

        if (chosenAddress) {
          await setDefaultAddress({ address: chosenAddress });
          userBilling.value = await loadUserBilling(true);
        }
      }

      await mergeItem('checkout', { billing: billingDetailsData }).finally(
        () => {
          isSubmitting.value = false;
        }
      );
    };

    const handleCheckSameAddress = async (value: boolean) => {
      sameAsShipping.value = value;

      billingDetails.value = props.shippingDetails as CheckoutAddressForm;
      oldBilling = { ...billingDetails.value };
      if (value) {
        currentAddressId.value = null;
        setAsDefault.value = false;
        if (billingDetails.value.country_code) {
          country.value =
            countriesList.value?.find(
              (country) => country.id === billingDetails?.value.country_code
            ) ?? null;
        }
        return;
      } else if (
        billingDetails?.value?.country_code &&
        isStateFieldVisible.value
      ) {
        await setRegionListByCountry(billingDetails?.value?.country_code);
      }

      if (isAuthenticated.value && !value) {
        if (oldBilling) {
          billingDetails.value = oldBilling;
          isAddNewAddressFormVisible.value = true;
        } else {
          billingDetails.value = getInitialCheckoutAddressForm();
        }
      }

      if (!isAuthenticated.value) {
        billingDetails.value = oldBilling;
      } else if (addresses.value.length) {
        const foundObject = addresses.value.find(
          (obj) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            obj.address_book_type === 'billing' && obj.default_billing === true
        );
        foundObject && (await handleSetCurrentAddress(foundObject));
      } else {
        billingDetails.value = getInitialCheckoutAddressForm();
      }

      if (billingDetails?.value?.country_code) {
        country.value = countriesList.value?.find(
          (country) => country.id === billingDetails?.value.country_code
        );
      }

      if (isRequiredFieldsFilled.value) {
        isBillingValid.value = true;
      } else {
        isBillingValid.value = false;
      }
    };
    const billingDetailsBeforeNewAddress = ref<CheckoutAddressForm>(
      getInitialCheckoutAddressForm()
    );
    const addressIdBeforeNewAddress = ref<number | null>(null);
    const handleAddNewAddressBtnClick = () => {
      addressIdBeforeNewAddress.value = currentAddressId.value;
      currentAddressId.value = null;
      billingDetailsBeforeNewAddress.value = { ...billingDetails.value };
      billingDetails.value = getInitialCheckoutAddressForm();
      phoneKey.value++;
      if (isJpStore.value) {
        billingDetails.value.country_code = 'JP';
      }
      isAddNewAddressFormVisible.value = true;
    };

    const handeCancelAddNewAddress = () => {
      currentAddressId.value = addressIdBeforeNewAddress.value;
      billingDetails.value = { ...billingDetailsBeforeNewAddress.value };
      isAddNewAddressFormVisible.value = false;
    };

    const handleSetCurrentAddress = async (
      customerAddress: CustomerAddress
    ) => {
      const id = customerAddress?.id;
      if (id) {
        currentAddressId.value = id;
        isAddNewAddressFormVisible.value = false;
      }
      billingDetails.value = addressFromApiToForm(customerAddress);
      country.value = customerAddress.country_code
        ? countriesList.value?.find(
            (country) => country.id === customerAddress.country_code
          )
        : null;
      chosenBillingAddress.value = customerAddress;

      if (isRequiredFieldsFilled.value) {
        isBillingValid.value = true;
      } else {
        isBillingValid.value = false;
      }
    };

    const isRequiredFieldsFilled: ComputedRef<boolean> = computed<boolean>(
      () => {
        const requiredFields = [
          'firstname',
          'lastname',
          'telephone',
          'street',
          'city',
          'country_code'
        ];
        if (!isZhHkStore.value) {
          requiredFields.push('postcode');
        }
        if (isJpStore.value) {
          requiredFields.push('region');
          requiredFields.push('region_id');
          requiredFields.push('lastnamekana');
          requiredFields.push('firstnamekana');
        }
        const billingDetailsLocal = { ...billingDetails.value };

        return requiredFields.every((field) => !!billingDetailsLocal[field]);
      }
    );

    const changeBillingDetails = async (
      field: keyof CheckoutAddressForm,
      value: string
    ) => {
      billingDetails.value[field] = value;
      currentAddressId.value = null;
      if (isJpStore.value && field === 'postcode') {
        await zip2Address();
      }

      let requiredFields = [
        'firstname',
        'lastname',
        'telephone',
        'street',
        'city',
        'postcode',
        'country_code'
      ];

      if (isPostcodeFieldVisible.value) {
        requiredFields.push('postcode');
      } else {
        requiredFields = requiredFields.filter((field) => field !== 'postcode');
      }

      if (isJpStore.value) {
        requiredFields.push('region');
        requiredFields.push('region_id');
        requiredFields.push('lastnamekana');
        requiredFields.push('firstnamekana');
      }

      if (isRequiredFieldsFilled.value && requiredFields.includes(field)) {
        isBillingValid.value = true;
      } else if (!requiredFields.includes(field)) {
        isBillingValid.value = true;
      }
    };

    const changeCountry = async (selectedCountry) => {
      changeBillingDetails('country_code', selectedCountry);
      const newCountry = await searchCountry({ id: selectedCountry });
      billingDetails.value.region = '';
      billingDetails.value.region_id = null;
      country.value = newCountry;
      isStateFieldVisible.value =
        selectedCountry === 'US' || selectedCountry === 'CA' || selectedCountry.id === 'JP';
      if (isStateFieldVisible.value) {
        regionList.value = country.value.available_regions?.reduce(
          (memo, cur) => {
            memo.push({
              region_code: cur.code,
              name: cur.name,
              region_id: cur.id
            });
            return memo;
          },
          []
        );
      }
    };

    const updateRegion = (desiredRegionId) => {
      if (
        !regionList.value.length ||
        regionList.value.length === 0 ||
        !desiredRegionId
      ) {
        return;
      }

      const foundState =
        regionList.value &&
        regionList.value.find((state) => state.region_id === +desiredRegionId);

      if (foundState) {
        billingDetails.value.region = foundState;
        billingDetails.value.region_id = +desiredRegionId;
      }
    };

    const setRegionListByCountry = async (selectedCountry = '') => {
      if (billingDetails.value.country_code !== '') {
        selectedCountry = billingDetails.value.country_code;
      } else {
        if (storeCode.value === 'en_us') {
          selectedCountry = 'US';
        } else if (storeCode.value === 'fr_us') {
          selectedCountry = 'CA';
        } else {
          return;
        }
      }

      const country = await searchCountry({ id: selectedCountry });
      if (selectedCountry === 'US' || selectedCountry === 'CA' || selectedCountry === 'JP') {
        regionList.value = country.available_regions?.reduce((memo, cur) => {
          memo.push({
            region_code: cur.code,
            name: cur.name,
            region_id: cur.id
          });
          return memo;
        }, []);
        preSelectRegion();
      }
    };


    const isSavedAddressValid = (address) => {
      return addresses.value.some((addr) => addr.id === address.id);
    };

    const saveBillingAddressData = async () => {
      try {
        const addressId = currentAddressId.value;
        const billingDetailsData = {
          billingDetails: addressFromCustomerToBillingCart({
            ...billingDetails.value,
            customerAddressId: addressId === null ? null : String(addressId),
            sameAsShipping: sameAsShipping.value,
            save_in_address_book: false
          })
        };

        billingDetailsData.billingDetails = setDefaultPostCode(
          billingDetailsData?.billingDetails
        );

        //@ts-ignore
        await save(billingDetailsData);
        Logger.info('Billing address is saved.');
      } catch (e) {
        Logger.error('saveBillingAddressData', e);
      }
    };

    const isHandlingNewBillingAddressSave = ref(false);
    const handleNewBillingAddressSave = async (reset: () => void) => {
      if (!isPhoneValid.value) {
        return;
      }
      if (isHandlingNewBillingAddressSave.value) return;
      isHandlingNewBillingAddressSave.value = true;
      const billingAddress = {
        address_book_type: 1,
        company: billingDetails.value.company,
        city: billingDetails.value.city,
        country_code: billingDetails.value.country_code,
        default_shipping: false,
        default_billing: false,
        firstname: billingDetails.value.firstname,
        lastname: billingDetails.value.lastname,
        postcode: billingDetails.value.postcode,
        street: [
          billingDetails.value.street,
          billingDetails.value.apartment || ''
        ],
        telephone: billingDetails.value.telephone,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        region: isStateFieldVisible.value
          ? {
              region:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                billingDetails.value?.region?.name ?? billingDetails.value?.region,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              region_id: billingDetails.value.region.region_id ?? billingDetails.value?.region_id
            }
          : null
      };

      if (isJpStore.value) {
        billingAddress['lastnamekana'] = billingDetails.value.lastnamekana;
        billingAddress['firstnamekana'] = billingDetails.value.firstnamekana;
      }

      await saveBillingAddressData();
      await createAddress(billingAddress);
      //@ts-ignore
      await handleSetCurrentAddress({
        ...billingAddress,
        id: createdAddressId.value
      });
      reset();
      isAddNewAddressFormVisible.value = false;
      isHandlingNewBillingAddressSave.value = false;
    };

    const createAddress = async (address) => {
      const result = await saveUserAddress({ address: address });
      if (result.id) {
        createdAddressId.value = result.id;
        await updateUserAddressesStore();
      } else {
        // TODO: Error catch
        console.error('Something went wrong with createAddress;');
      }
    };

    const updateUserAddressesStore = async () => {
      const userAddresses = await loadUserAddresses({
        getCustomerAddresses: 'customerAddresses'
      });
      customerStore.user.addresses = userAddresses;
    };

    const billingCountry = computed({
      get() {
        return countriesList.value.find(
          (country) => country.id === billingDetails.value.country_code
        );
      },
      set(newValue) {
        nextTick(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          billingDetails.value.country_code = newValue.id;
        });
      }
    });

    onMounted(async () => {
      const vsfCheckoutData = lsGet('vsf-checkout');
      billingBoundToLocalStorage.value =
        vsfCheckoutData?.billing?.billingDetails;

      const validStep = await isPreviousStepValid('user-account');
      if (!validStep) {
        await router.push(app.localeRoute({ name: 'user-account' }));
      }
      let loadedUserBilling;
      const [loadedBillingInfoBoundToCart] =
        await Promise.all([
          loadBilling()
        ]);
      if (!lsGet('loaded_user_addresses')) {
        loadedUserBilling = await loadUserBilling();
      } else {
        loadedUserBilling = lsGet('loaded_user_addresses');
      }
      const [defaultAddress = null] = userBillingGetters.getAddresses(
        loadedUserBilling,
        { default_billing: true }
      );
      const wasBillingAddressAlreadySetOnCart = Boolean(
        loadedBillingInfoBoundToCart
      );

      let userAddressIdenticalToSavedCartAddress;

      // keep in mind default billing address is set on a customer's cart during cart creation
      if (billingBoundToLocalStorage.value) {
        userAddressIdenticalToSavedCartAddress =
          findUserAddressIdenticalToSavedCartAddress(
            loadedUserBilling?.addresses,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            billingBoundToLocalStorage.value,
            true
          );
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          typeof billingBoundToLocalStorage.value.sameAsShipping !== 'undefined'
        ) {
          sameAsShipping.value =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            billingBoundToLocalStorage.value?.sameAsShipping ?? false;
        }
      } else if (wasBillingAddressAlreadySetOnCart) {
        billingInfoBoundToCart.value = { ...loadedBillingInfoBoundToCart };

        if (!billingInfoBoundToCart.value.company) {
          billingInfoBoundToCart.value.company = undefined;
        }

        billingInfoBoundToCart.value.region = undefined;

        userAddressIdenticalToSavedCartAddress =
          findUserAddressIdenticalToSavedCartAddress(
            loadedUserBilling?.addresses,
            billingInfoBoundToCart.value,
            true
          );
      }

      if (userAddressIdenticalToSavedCartAddress) {
        handleSetCurrentAddress({
          ...userAddressIdenticalToSavedCartAddress,
          id: userAddressIdenticalToSavedCartAddress?.id
        });
      } else if (defaultAddress) {
        handleSetCurrentAddress(defaultAddress);
      } else if (
        billingBoundToLocalStorage.value &&
        Object.keys(billingBoundToLocalStorage.value).length
      ) {
        billingDetails.value = addressFromApiToForm(
          billingBoundToLocalStorage.value
        );
      }

      if (billingDetails.value?.country_code) {
        country.value =
          countriesList.value?.find(
            (country) => country.id === billingDetails.value.country_code
          ) ?? null;
      }

      userBilling.value = loadedUserBilling;
      const countriesFromStore = await countryStore.loadCountriesList();
      countries.value = countriesFromStore.map((country) => ({
        id: country.id,
        label: country.full_name_locale,
        englishLabel: country.full_name_english,
        abbreviation: country.two_letter_abbreviation
      }));

      if (props.isStateFieldShow && billingDetails.value?.country_code === '') {
        isStateFieldVisible.value = true;
      } else {
        isStateFieldVisible.value =
          billingDetails.value?.country_code === 'US' ||
          billingDetails.value?.country_code === 'CA' ||
          billingDetails.value?.country_code === 'JP';
      }

      if (isAuthenticated.value) {
        if (!billingBoundToLocalStorage.value) {
          if (defaultAddress) {
            chosenBillingAddress.value = defaultAddress;
            sameAsShipping.value = true;
            await handleCheckSameAddress(sameAsShipping.value);
          } else {
            isAddNewAddressFormVisible.value = true;
            if (!regionList.value.length) {
              await setRegionListByCountry();
            } else {
              preSelectRegion();
            }
          }
        }
        if (!billingDetails.value.firstname) {
          billingDetails.value.firstname = user.value.firstname;
        }

        if (!billingDetails.value.lastname) {
          billingDetails.value.lastname = user.value.lastname;
        }
      } else {
        if (sameAsShipping.value) {
          await handleCheckSameAddress(sameAsShipping.value);
        }
        if (!regionList.value.length) {
          await setRegionListByCountry();
        } else {
          preSelectRegion();
        }
      }
      //if the billing address is empty
      if (!billingDetails.value?.street) {
        await handleCheckSameAddress(true);
      }
    });

    const customMessages = {
      required: 'Mandatory input'
    };

    const isPhoneMaskWitFlagEnabled = computed(
      () => config.value.phone_mask_with_flag_enabled
    );

    const phoneValidHandler = (value) => {
      isPhoneValid.value = value;
      emit('onBillingPhoneChanged', value);
    };

    const updateAddress = async (address) => {
      if (!address) {
        return false;
      }

      billingDetails.value = {
        ...addressFromApiToForm(address, true)
      };
    };

    return {
      isSubmitting,
      isDeStore,
      isPhoneMaskWitFlagEnabled,
      customMessages,
      billingCountry,
      isAddNewAddressFormVisible,
      canMoveForward,
      changeCountry,
      updateRegion,
      changeBillingDetails,
      countriesList,
      countries,
      country,
      currentAddressId,
      handleAddNewAddressBtnClick,
      handleAddressSubmit,
      handleSetCurrentAddress,
      handleCheckSameAddress,
      hasSavedBillingAddress,
      isAuthenticated,
      isFormSubmitted,
      loading,
      regionInformation,
      searchCountry,
      regionList,
      setAsDefault,
      billingDetails,
      sameAsShipping,
      addresses,
      handleNewBillingAddressSave,
      isHandlingNewBillingAddressSave,
      chosenBillingAddress,
      isStateFieldVisible,
      handeCancelAddNewAddress,
      isRequiredFieldsFilled,
      getRules,
      isBillingValid,
      isZhHkStore,
      isPostcodeFieldVisible,
      isJpStore,
      config,
      phoneKey,
      nameKanaRules,
      phoneValidHandler,
      isPhoneValid,
      updateAddress
    };
  }
});
