




























































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { SfLink, SfProductCard } from '@storefront-ui/vue';

import VaimoBadge from '~/diptyqueTheme/components/atoms/VaimoBadge.vue';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import productStatuses from '~/diptyqueTheme/config/productStatuses';
import { useCartStore } from '~/modules/checkout/stores/cart';

export default defineComponent({
  name: 'VaimoProductCard',
  components: {
    SfProductCard,
    SfLink,
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    EngravingInfoPleat: () =>
      import('organisms/product/EngravingInfoPleat.vue'),
    VaimoBadge
  },

  props: {
    image: {
      type: [Array, Object, String],
      default: ''
    },
    imageSource: {
      type: String,
      default: 'magento'
    },
    hoverImage: {
      type: [Array, Object, String],
      default: null
    },
    loadingFlag: Boolean,
    sku: {
      required: false,
      type: String
    },
    productType: {
      type: String,
      default: 'SimpleProduct'
    },
    fallbackImage: {
      type: [Array, Object, String],
      default: null
    },
    imageWidth: {
      type: [Number, String],
      default: null
    },
    imageHeight: {
      type: [Number, String],
      default: null
    },
    relatedProductsQnty: {
      type: [Number, String],
      default: null
    },
    capacity: {
      type: [Number, String, Array],
      default: null
    },
    variantName: {
      type: [Number, String, Array],
      default: null
    },
    badgeLabel: {
      type: String,
      default: ''
    },
    badgeColor: {
      type: String,
      default: ''
    },
    colors: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    link: {
      type: [String, Object],
      default: null
    },
    linkTag: {
      type: String,
      default: undefined
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false
    },
    maxRating: {
      type: [Number, String],
      default: 5
    },
    basePrice: {
      type: [Number, String],
      default: null
    },
    regularPrice: {
      type: [Number, String],
      default: null
    },
    specialPrice: {
      type: [Number, String],
      default: null
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: 'heart'
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: 'heart_fill'
    },
    isInWishlist: {
      type: Boolean,
      default: false
    },
    showAddToCartButton: {
      type: Boolean,
      default: false
    },
    isAddedToCart: {
      type: Boolean,
      default: false
    },
    addToCartDisabled: {
      type: Boolean,
      default: false
    },
    imageTag: {
      type: String,
      default: ''
    },
    sizeDesktop: {
      type: String,
      required: false,
      default: 'l'
    },
    sizeMobile: {
      type: String,
      required: false,
      default: 'l'
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({})
    },
    editorialData: {
      type: [Array, null],
      default: null
    },
    loading: Boolean,
    stockStatus: {
      required: false,
      type: String
    },
    isSalable: {
      required: false,
      type: Boolean,
      default: true
    },
    isDynamicPrice: Boolean
  },
  emits: ['addToCart'],

  setup(props, { emit, refs }) {
    const {
      app: { i18n }
    } = useContext();
    const storeCode = i18n.localeProperties.code;
    const isJpStore = storeCode === 'ja_jp';
    const router = useRouter();
    const isAdded = ref(false);
    const cartStore = useCartStore();
    const { isDesktop } = useScreenSize();
    const desktopSizesMap = {
      l: 'desktop-l',
      m: 'desktop-m',
      s: 'desktop-s'
    };
    const mobileSizesMap = {
      l: 'mobile-l',
      m: 'mobile-m',
      s: 'mobile-s'
    };
    // TODO: Make new logic with hover effect
    const getSizeClasses = computed(() => {
      return [
        desktopSizesMap[props.sizeDesktop],
        mobileSizesMap[props.sizeMobile]
      ];
    });
    const addToCart = () => {
      emit('addToCart');
    };

    const getProductPrice = computed(() => {
      const price = (props as { regularPrice: number }).regularPrice;
      return (props as { isDynamicPrice: boolean }).isDynamicPrice
        ? i18n.t('From {price}', {
            price
          })
        : price;
    });

    const isMerging = computed(() => cartStore.is_data_loading);

    const isHoverImageAvailable = computed(() => props.hoverImage !== null);

    const isEditorialDataAvailable = computed(() => {
      return props.editorialData !== null && props.editorialData.length;
    });

    const isOutOfStock = computed(() => {
      return (
        props.stockStatus === productStatuses.out_of_stock || !props.isSalable
      );
    });

    const goToProduct = (link) => {
      router.push(link);
    };

    onMounted(() => {
      // Making svg icons not accessible to pass the Level Access test
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      const icons = refs.productCard?.$el.querySelectorAll('span.sf-icon');
      if (icons && icons.length) {
        icons.forEach((el) => {
          el.setAttribute('aria-hidden', 'true');
        });
      }
    });

    const isAddToCartButtonShown = computed(
      () =>
        !isOutOfStock.value &&
        !isMerging.value &&
        props.productType === 'SimpleProduct'
    );
    const isCreateThisSetButtonShown = computed(
      () =>
        !isOutOfStock.value &&
        !isMerging.value &&
        props.productType === 'BundleProduct'
    );

    return {
      isJpStore,
      addToCart,
      goToProduct,
      getProductPrice,
      isOutOfStock,
      isAdded,
      getSizeClasses,
      isDesktop,
      isHoverImageAvailable,
      isEditorialDataAvailable,
      cartStore,
      isMerging,
      isAddToCartButtonShown,
      isCreateThisSetButtonShown
    };
  }
});
