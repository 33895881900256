<template>
  <div v-if="buttonVisibility">
    <SfButton
      v-if="!email"
      class="vaimo-button"
      :type="link ? null : type"
      :link="getComputedLink"
      :target="link && getLinkTarget"
      :class="getButtonClasses"
      v-on="$listeners"
      :disabled="disabled"
      v-bind="$attrs"
      @keypress.enter="onClick"
      @click="onClick"
    >
      <template #default>
        <div class="vaimo-button__wrapper" :class="getWrapperClass">
          <VaimoIcon
            v-if="icon"
            :icon="icon"
            class="button-icon"
            :label="icon"
          />
          <slot />
          <span v-if="additionalData" class="vaimo-button__additional-data">
            {{ additionalData }}
          </span>
        </div>
      </template>
    </SfButton>
    <VaimoNewsletterSubscription
      v-else
      hide-header
      :label="text"
      :submit-button-text="emailButtonText"
      :force-show-submit="locale === 'en_uk' || forceShowSubmit"
      :countdown-email="countdownEmail"
    />
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  toRef,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';

import { useUiNotification, useUiState } from '~/composables';
import { useBooxi } from '~/diptyqueTheme/composable/useBooxi';
import { useLink } from '~/diptyqueTheme/composable/useLink';
import {
  generateGoogleCalendarLink,
  generateICSFile
} from '~/diptyqueTheme/helpers/generateCalendarInvitation';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';

export default defineComponent({
  name: 'VaimoButton',
  components: {
    SfButton,
    VaimoNewsletterSubscription: () =>
      import('organisms/VaimoNewsletterSubscription.vue')
  },
  props: {
    variant: {
      required: false,
      type: String,
      default: 'primary',
      validator: (value) =>
        [
          'primary',
          'default',
          'link',
          'circle',
          'small',
          'link with arrow',
          'link grey',
          'edito link'
        ].includes(value)
    },
    type: {
      required: false,
      type: String,
      default: 'button'
    },
    email: Boolean,
    iconPosition: {
      required: false,
      type: String,
      default: 'right',
      validator: (value) => ['right', 'left'].includes(value)
    },
    labelCentered: {
      required: false,
      type: Boolean,
      default: false
    },
    fullWidth: {
      required: false,
      type: Boolean,
      default: false
    },
    link: {
      required: false,
      type: String,
      default: null
    },
    emailButtonText: {
      type: String,
      default: ''
    },
    icon: {
      required: false,
      type: String,
      default: ''
    },
    linkBehavior: {
      required: false,
      type: String,
      default: 'open in current tab',
      validator: (value) =>
        ['open in current tab', 'open in new tab'].includes(value)
    },
    preventDefault: Boolean,
    disabled: Boolean,
    loading: Boolean,
    block: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    text: String,
    buttonVisibility: {
      required: false,
      type: Boolean,
      default: true
    },
    layerContent: {
      required: false,
      type: [Object, null],
      default: null
    },
    additionalData: {
      required: false,
      type: [String, null],
      default: null
    },
    calendarData: {
      type: Object,
      required: false,
      default: () => ({})
    },
    isBooxiButton: Boolean,
    isCalendarButton: Boolean,
    forceShowSubmit: Boolean,
    countdownEmail: Boolean
  },

  setup(props) {
    const { normalizeLink } = useLink();
    const { openContentLayer } = useUiState();
    const { addItemToCart } = useAddToCart();
    const { getProductDetailsToAddToCart } = useProduct();
    const { send: sendNotification } = useUiNotification();
    const router = useRouter();
    const { i18n } = useContext();
    const { app } = useContext();
    const { triggerBooxiModal } = useBooxi();
    const locale = i18n.localeProperties?.code;
    const { isAndroid } = app.$device;

    const _disabled = ref(false);
    const disabled = toRef(props, 'disabled');
    const isDisabled = computed(() => disabled.value || _disabled.value);

    const isPreventedDefault = toRef(props, 'preventDefault');

    const variants = [
      'primary',
      'default',
      'link',
      'circle',
      'small',
      'link with arrow',
      'link grey',
      'edito link'
    ];
    const variantClasses = computed(() => {
      return variants.reduce((memo, variant) => {
        let variantClass = variant.replace(/ /g, '-');
        memo[`vaimo-button__${variantClass}`] = props.variant === variant;
        return memo;
      }, {});
    });

    const getButtonClasses = computed(() => {
      return {
        'vaimo-button__block': props.block,
        'vaimo-button__disabled': isDisabled.value,
        'w-full': props.fullWidth,
        ...variantClasses.value
      };
    });

    const getWrapperClass = computed(() => {
      return {
        'icon-right': props.iconPosition === 'right',
        'icon-left': props.iconPosition === 'left',
        center: props.labelCentered,
        'justify-between': props.additionalData
      };
    });

    const getLinkTarget = computed(() => {
      const targetMap = {
        'open in current tab': '_self',
        'open in new tab': '_blank'
      };

      return targetMap[props.linkBehavior];
    });

    const getComputedLink = computed(() => {
      if (
        isPreventedDefault.value ||
        isDisabled.value ||
        props.isBooxiButton ||
        props.isCalendarButton
      )
        return '';
      if (props.layerContent || addToCartSku.value) return ''; // Prevent go to next page if Layer is configured or addToCartSku is set

      if (props.link?.includes(process.env.VSF_STORE_URL)) {
        return props.link?.replace(process.env.VSF_STORE_URL, '');
      }

      return normalizeLink(props.link);
    });

    const addToCartSku = computed(() => {
      return props.link?.split('#addToCart:')[1]?.trim();
    });

    const onClick = async () => {
      if (!getComputedLink.value) {
        if (addToCartSku.value) {
          addToCart(addToCartSku.value);
        } else if (props.layerContent) {
          const { heading, content } = props.layerContent;
          openContentLayer(heading, content);
        } else if (props.isBooxiButton) {
          triggerBooxiModal();
        } else if (props.isCalendarButton) {
          const event = {
            end: props.calendarData.endDate,
            title: props.calendarData.title,
            description: props.calendarData.description
          };
          await generateICSFile(event);
          if (isAndroid) {
            generateGoogleCalendarLink(event);
          }
        }
      }
    };

    const addToCart = async (sku) => {
      _disabled.value = true;

      try {
        const result = await getProductDetailsToAddToCart(
          {
            filter: {
              sku: {
                eq: sku
              }
            }
          },
          true
        );
        if (result?.items?.[0]) {
          await addItemToCart({ product: result.items[0], quantity: 1 });
        } else {
          sendNotification({
            message: i18n.t('This product is currently unavailable'),
            persist: false,
            type: 'danger',
            area: 'top'
          });
        }
      } catch (error) {
        router.push({ name: 'error' });
        console.error(error);
      } finally {
        _disabled.value = false;
      }
    };

    return {
      variantClasses,
      getLinkTarget,
      getComputedLink,
      getWrapperClass,
      getButtonClasses,
      onClick,
      locale
    };
  }
});
</script>

<style lang="scss" scoped>
@import '@/diptyqueTheme/assets/styles/atoms/VaimoButton';
</style>
