
























































import {
  computed,
  defineComponent,
  ref,
  useFetch,
  useRoute,
  watch,
  onBeforeUnmount
} from '@nuxtjs/composition-api';
import { useUserOrder } from '~/modules/customer/composables/useUserOrder';
import type { CustomerOrders } from '~/modules/GraphQL/types';
import { SfLoader } from '@storefront-ui/vue';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import {MetaInfo} from "vue-meta";

export default defineComponent({
  name: 'AccountOrders',
  components: {
    AccountHeading: () => import('organisms/account/parts/AccountHeading.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoHelpBlock: () => import('organisms/VaimoHelpBlock.vue'),
    AccountOrder: () =>
      import('organisms/account/parts/order/AccountOrder.vue'),
    SfLoader
  },
  head(): MetaInfo {
    const pageTitle = this.$i18n.t(this.$route?.meta?.titleLabel ?? '');
    return {
      title: pageTitle as string
    };
  },
  setup() {
    const { search } = useUserOrder();
    const route = useRoute();
    const {
      query: { page, itemsPerPage }
    } = route.value;
    const { isDesktop } = useScreenSize();
    const orderCompleteStatuses = ['Complete', 'Closed', '[SFS] Cancelled', '[SFS] Delivered'];
    const orderCancelledStatuses = ['Cancelled', 'Canceled', '[SFS] Aborted', '[SFS] Refused', '[SFS] Returned'];

    const rawCustomerOrders = ref<CustomerOrders | null>(null);
    const orders = computed(() => {
      return rawCustomerOrders.value?.items.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        return dateB.getTime() - dateA.getTime();
      }) ?? []
    });
    const getOrdersByStatus = (orderArr, status) => {
      if (typeof status === 'string') {
        return orderArr.filter((obj) => obj.status === status);
      }
      if (Array.isArray(status)) {
        return orderArr.filter((obj) => status.indexOf(obj.status) >= 0);
      }
    };

    const tabsNav = ref([
      {
        status: 'all',
        title: 'See everything',
        qty: computed(() => orders.value.length)
      },
      {
        status: 'inProgress',
        title: 'Processing',
        qty: computed(
          () =>
            orders.value.filter((obj) => {
              return (
                orderCompleteStatuses.indexOf(obj.status) < 0 &&
                orderCancelledStatuses.indexOf(obj.status) < 0 &&
                obj.status !== 'Returned' &&
                obj.status !== 'Shipped'
              );
            }).length
        )
      },
      {
        status: 'shipped',
        title: 'Shipped',
        qty: computed(() => getOrdersByStatus(orders.value, 'Shipped').length)
      },
      {
        status: 'completed',
        title: 'Completed',
        qty: computed(() => getOrdersByStatus(orders.value, orderCompleteStatuses).length)
      },

      // This tab is hidden according to the requirements of DPTQ-3106
      // {
      //   status: 'returned',
      //   title: 'Returns',
      //   qty: computed(() => getOrdersByStatus(orders.value, 'Returned').length)
      // },
      {
        status: 'cancelled',
        title: 'Canceled',
        qty: computed(() => getOrdersByStatus(orders.value, orderCancelledStatuses).length)
      }
    ]);

    const activeTab = ref<string>('');
    const setActiveTab = function (tab) {
      activeTab.value = tab;
    };
    const tabsData = ref([]);
    const defaultTab = computed(() => (activeTab.value = 'all'));
    const isOpenId = ref([]);

    const changeBlockVisibility = (num) => {
      if (isOpenId.value.includes(num)) {
        const index = isOpenId.value.indexOf(num);
        isOpenId.value.splice(index, 1);
      } else {
        isOpenId.value.push(num);
      }
    };

    watch(activeTab, () => {
      isOpenId.value = [];
      if (activeTab.value === 'all') {
        tabsData.value = orders.value;
      } else if (activeTab.value === 'completed') {
        tabsData.value = getOrdersByStatus(orders.value, orderCompleteStatuses);
      } else if (activeTab.value === 'shipped') {
        tabsData.value = getOrdersByStatus(orders.value, 'Shipped');
      // This tab is hidden according to the requirements of DPTQ-3106
      // } else if (activeTab.value === 'returned') {
      //   tabsData.value = getOrdersByStatus(orders.value, 'Returned');
      } else if (activeTab.value === 'cancelled') {
        tabsData.value = getOrdersByStatus(orders.value, orderCancelledStatuses);
      } else if (activeTab.value === 'inProgress') {
        tabsData.value = orders.value.filter((obj) => {
          return (
            orderCompleteStatuses.indexOf(obj.status) < 0 &&
            orderCancelledStatuses.indexOf(obj.status) < 0 &&
            obj.status !== 'Returned' &&
            obj.status !== 'Shipped'
          );
        });
      }
    });

    useFetch(async () => {
      rawCustomerOrders.value = await search({
        currentPage: Number.parseInt(page as string, 10) || 1,
        pageSize: 100,
        customQuery: {
          customerOrders: 'customerOrdersWithProductImg'
        }
      });
    });

    const nav = ref(null);
    let pos = { top: 0, left: 0, x: 0, y: 0 };

    const mouseMoveHandler = function (e) {
      // How far the mouse has been moved
      const dx = e.clientX - pos.x;
      const dy = e.clientY - pos.y;

      // Scroll the element
      nav.value.scrollTop = pos.top - dy;
      nav.value.scrollLeft = pos.left - dx;
    };
    const mouseUpHandler = function () {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);

      nav.value.style.cursor = 'grab';
      nav.value.style.removeProperty('user-select');
    };

    const mouseDownHandler = (event) => {
      if (!isDesktop) {
        return;
      }

      nav.value.style.cursor = 'grabbing';
      pos = {
        // The current scroll
        left: nav.value.scrollLeft,
        top: nav.value.scrollTop,
        // Get the current mouse position
        x: event.clientX,
        y: event.clientY
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    // clean it up
    onBeforeUnmount(() => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
    });

    return {
      orders,
      rawCustomerOrders,
      tabsNav,
      activeTab,
      tabsData,
      setActiveTab,
      defaultTab,
      nav,
      mouseDownHandler,
      isOpenId,
      changeBlockVisibility
    };
  }
});
