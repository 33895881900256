
























































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  Ref,
  ref,
  ssrRef,
  useContext
} from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { onSSR } from '@vue-storefront/core';

import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useImage, useUiHelpers, useUiState } from '~/composables';
import SectionsList from '~/diptyqueTheme/components/templates/SectionsList.vue';
import { useGoogleTagManager } from '~/diptyqueTheme/composable';
import { useFacet } from '~/diptyqueTheme/modules/catalog/category/composables/useFacet';
import { getCustomFiltersByCategoryIdCommand } from '~/diptyqueTheme/modules/catalog/category/customFilters/command/getCustomFiltersByCategoryIdCommand';
import { useCategoryDataStore } from '~/diptyqueTheme/modules/catalog/category/store/categoryData';
import { useFiltersStore } from '~/diptyqueTheme/stores/filters';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useCategoryContent } from '~/modules/catalog/category/components/cms/useCategoryContent';
import { getProductFilterByCategoryCommand } from '~/modules/catalog/category/components/filters/command/getProductFilterByCategoryCommand';
import type { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';
import { usePrice } from '~/modules/catalog/pricing/usePrice';
import type { Product } from '~/modules/catalog/product/types';
import type { ProductInterface } from '~/modules/GraphQL/types';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';

export default defineComponent({
  name: 'CategoryDefault',
  components: {
    CategoryEmptyResults: () =>
      import('~/modules/catalog/category/components/CategoryEmptyResults.vue'),
    CategoryFilters: () =>
      import(
        '~/modules/catalog/category/components/filters/CategoryFilters.vue'
      ),
    CmsContent: () =>
      import('~/modules/catalog/category/components/cms/CmsContent.vue'),
    CategoryProductGrid: () =>
      import(
        '~/modules/catalog/category/components/views/CategoryProductGrid.vue'
      ),
    CategoryProductList: () =>
      import(
        '~/modules/catalog/category/components/views/CategoryProductList.vue'
      ),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue'),
    LoadMore: () => import('atoms/LoadMore.vue'),
    VaimoBreadcrumbs: () => import('atoms/VaimoBreadcrumbs.vue'),
    SkeletonLoader,
    SectionsList,
    VaimoCustomFilters: () =>
      import(
        '~/diptyqueTheme/components/organisms/category/VaimoCustomFilters.vue'
      )
  },
  transition: 'fade',
  props: {
    breadcrumbs: {
      type: Array,
      default: () => []
    },
    categoryData: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const isCategoryDataNotEmpty = props.categoryData
      ? computed(() => Object.keys(props.categoryData).length !== 0)
      : false;
    const activeCategory = computed(() => props.categoryData);
    const { app } = useContext();
    const { getViewItemListDetails } = useGoogleTagManager();
    const {
      imageSizes: { productCard: imageSize }
    } = useImage();
    const { getContentData } = useCategoryContent();
    const { addTags } = useCache();
    const uiHelpers = useUiHelpers();
    const cmsContent = ref('');
    const isShowCms = ssrRef(false);
    const isShowProducts = ssrRef(false);
    const productContainerElement = ref<HTMLElement | null>(null);

    const {
      toggleFilterSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isCategoryGridView,
      isFilterSidebarOpen
    } = useUiState();
    const filtersStore = useFiltersStore();
    const {
      addItem: addItemToWishlistBase,
      isInWishlist,
      removeItem: removeItemFromWishlist
    } = useWishlist();
    const {
      result,
      search,
      searchWithoutReactive,
      loading,
      alternativeLoading,
      error: facetError
    } = useFacet();
    const { addItemToCart } = useAddToCart();

    const addItemToWishlist = async (product: Product) => {
      await (isInWishlist({ product })
        ? removeItemFromWishlist({ product })
        : addItemToWishlistBase({ product }));
    };
    const { getPricesBySku } = usePrice();
    const categoryDataStore = useCategoryDataStore();

    const sortBy: Ref<SortingModel> = computed(
      () =>
        facetGetters.getSortOptions(result.value) || {
          selected: '',
          options: []
        }
    );
    const activeCategoryName = computed(() => activeCategory.value?.name ?? '');
    const aggregations = computed(() => result.value?.data?.aggregations);
    const pagination = computed(() => facetGetters.getPagination(result.value));

    const mixedProducts = computed(() =>
      categoryDataStore.getMixedProducts.filter((p) => p)
    );

    const belowBlocks = computed(() => {
      let blocks = [...categoryDataStore.getBlocksBelow].filter((b) => b);
      return blocks.filter((b) => b);
    });
    const categoryUid = computed(() => activeCategory.value?.uid);
    const categoryId = computed(() => activeCategory.value?.id);
    const loadedFilters = ssrRef([]);

    const loadAllFilters = async () => {
      const [regularFilters, customFilters] = await Promise.all([
        getProductFilterByCategoryCommand.execute({
          eq: activeCategory.value?.uid
        }),
        getCustomFiltersByCategoryIdCommand.execute(
          activeCategory.value?.id,
          [],
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore "getCustomFiltersByCategoryId" dynamically added by custom-middleware
          app.$vsf.$magento.api.getCustomFiltersByCategoryId
        )
      ]);

      const formattedCustomFilters = [];
      if (Array.isArray(customFilters)) {
        customFilters.forEach((filter) => {
          // @ts-ignore
          filter?.options.forEach((option) => {
            formattedCustomFilters.push({
              attribute_code: option.name
            });
          });
        });
      }

      loadedFilters.value = [...regularFilters, ...formattedCustomFilters];
    };

    const getFilteredFacets = (facets) => {
      const validFilters = loadedFilters.value.map(
        (filter) => filter.attribute_code
      );
      const { filters } = facets;
      // remove url parameters which is not a filter
      Object.keys(filters).forEach((filter) => {
        if (!validFilters.includes(filter)) {
          delete facets.filters[filter];
        }
      });

      return facets;
    };

    const categoryDescription = computed(() => ({
      text: activeCategory.value.description,
      lines: 2,
      truncate: 'by lines'
    }));

    const fetchProducts = async () => {
      const facetsFromUrl = getFilteredFacets(uiHelpers.getFacetsFromURL());
      const facetsFromUrlsKeys = Object.keys(facetsFromUrl?.filters ?? {});
      if (facetsFromUrlsKeys?.length === 1) {
        filtersStore.lastSelectedFilter = {
          attribute_code: facetsFromUrlsKeys[0],
          options: facetsFromUrl.filters[facetsFromUrlsKeys[0]]
        };
      }

      const params = {
        ...getFilteredFacets(uiHelpers.getFacetsFromURL()),
        category_uid: activeCategory.value?.uid,
        last_added_filter_attribute: filtersStore.lastSelectedFilter
      };

      await search(params);

      categoryDataStore.currentRouteData = getFilteredFacets(
        uiHelpers.getFacetsFromURL()
      );

      categoryDataStore.categoryProducts = facetGetters
        .getProducts(result.value)
        .filter(Boolean);
    };
    const doInitialLoading = ref(true);

    onSSR(async () => {
      categoryDataStore.categoryProducts = [];
      categoryDataStore.currentRouteData = {};
      doInitialLoading.value = process.server;
      categoryDataStore.categoryProducts = [];
      categoryDataStore.currentRouteData = {};

      if (doInitialLoading.value || !loadedFilters.value.length) {
        await loadAllFilters();
      }

      const [content] = await Promise.all([
        getContentData(activeCategory.value?.uid),
        categoryDataStore.loadBlocksList(String(activeCategory.value?.id || 0)),
        fetchProducts()
      ]);

      cmsContent.value = content?.cmsBlock?.content ?? '';
      isShowCms.value = content?.isShowCms;
      isShowProducts.value = content?.isShowProducts;

      if (process.client && !doInitialLoading.value) {
        await getPriceData();
      }

      if (process.server) {
        const productTags = categoryDataStore.categoryProducts.map(
          (product) => ({
            prefix: CacheTagPrefix.Product,
            value: product.uid
          })
        );

        addTags(productTags);
      }
    });

    const priceData = ref(null);
    const isPriceLoaded = ref(false);

    const getPriceData = async () => {
      const productItems = activeCategory.value?.products?.items;
      const skus = productItems.map((item) => item.sku).filter(Boolean);
      priceData.value = await getPricesBySku(
        skus,
        pagination.value.itemsPerPage
      );
      productItems.forEach((product) => {
        product.price_range = priceData.value.items.find(
          (item) => item.sku === product.sku
        )?.price_range;
      });
      isPriceLoaded.value = true;
    };

    onMounted(async () => {
      if (doInitialLoading.value) {
        doInitialLoading.value = false;

        if (activeCategory.value?.products?.items?.length > 0) {
          await getPriceData();
        }
      }
      nextTick(() => {
        if (mixedProducts.value) {
          app.$gtm.push(getViewItemListDetails(mixedProducts.value, 'plp'));
        }
      });
    });

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false);
      fetchProducts();
    };

    const doChangeItemsPerPage = (itemsPerPage: number) => {
      uiHelpers.changeItemsPerPage(itemsPerPage, false);
      goToPage(0);
    };

    const onReloadProducts = () => {
      goToPage(0);
    };

    const appendProducts = async (page: number) => {
      if (categoryUid.value) {
        const facet = {
          ...getFilteredFacets(uiHelpers.getFacetsFromURL()),
          page
        };
        const result = await searchWithoutReactive({
          ...facet,
          category_uid: categoryUid.value
        });

        if (result && !facetError.value.search) {
          const appendProducts: Array<ProductInterface> =
            facetGetters.getProducts(result) ?? [];

          appendProducts.forEach((product) => {
            categoryDataStore.pushIntoCategoryProducts(product);
          });
        }
      }
    };

    const categoryProducts = computed(() => {
      return categoryDataStore.categoryProducts.filter((p) => p);
    });

    const productsTotal = computed(() => categoryProducts.value.length);

    return {
      isPriceLoaded,
      ...uiHelpers,
      toggleFilterSidebar,
      isCategoryGridView,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      mixedProducts,
      belowBlocks,
      sortBy,
      isShowCms,
      isShowProducts,
      cmsContent,
      activeCategoryName,
      doChangeItemsPerPage,
      productContainerElement,
      aggregations,
      categoryDescription,
      onReloadProducts,
      goToPage,
      appendProducts,
      categoryUid,
      categoryId,
      loading,
      alternativeLoading,
      imageSize,
      categoryProducts,
      productsTotal,
      isCategoryDataNotEmpty
    };
  }
});
