






























































































































import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  watch
} from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';
import VaimoBanner from 'atoms/VaimoBanner.vue';

import { useUiState } from '~/composables/useUiState';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';

interface ProductImage {
  __typename: string;
  disabled: boolean;
  label: string;
  position: number;
  url: string;
}
export default defineComponent({
  name: 'ProductMainGallery',
  components: {
    VaimoBanner,
    SfBreadcrumbs,
    VaimoBadge: () => import('atoms/VaimoBadge.vue'),
    EngravingInfoPleat: () => import('organisms/product/EngravingInfoPleat.vue')
  },
  props: {
    additionalData: {
      required: true,
      type: Object
    },
    images: {
      required: true,
      type: Array as PropType<ProductImage[]>
    }
  },
  emits: ['carouselChanged'],
  setup(props, { emit }) {
    const { isMobile } = useScreenSize();
    const carousel = ref(null);
    const isLoaded = ref(false);

    watch(carousel, () => {
      emit('carouselChanged', carousel);
    });

    const productEmptyImage = {
      url: '',
      position: 1,
      disabled: false,
      label: 'revamp',
      __typename: 'ProductImage'
    };
    const mainImage = computed(() => props.images[0] ?? productEmptyImage);
    const images = computed(() => props.images ?? []);
    const IMAGE_REVAMP = 'revamp';

    const getFilteredGallery = computed(() => {
      if (images.value) {
        const revampImages = images.value
          .filter((image) => image.label === IMAGE_REVAMP)
          .sort((a, b) => a.position - b.position);

        // In this case [mainImage.value] is fallback if !revampImages.length
        return revampImages.length ? revampImages : [mainImage.value];
      }
    });

    const zoomedOnDesktop = ref(false);
    const desktopZoom = (index) => {
      if (isMobile.value) return;
      carousel.value.$el.style.width =
        Math.round((window.innerHeight / 4) * 3) + 'px';
      carousel.value.goTo(index, true);
      zoomedOnDesktop.value = true;
    };

    const galleryWrapperClasses = ref([]);
    watch(zoomedOnDesktop, () => {
      if (zoomedOnDesktop.value) {
        galleryWrapperClasses.value.push('zoomed');
        setTimeout(() => {
          galleryWrapperClasses.value.push('fadeIn');
        }, 50);
      } else {
        galleryWrapperClasses.value = [];
      }
    });

    onMounted(() => {
      nextTick(() => {
        isLoaded.value = true;
      });
    });

    const {
      isProductZoomOpened,
      openEngravingInfo,
      hideEngravingInfo,
      isEngravingInfoOpen
    } = useUiState();

    const goToEngravingInfo = () => {
      openEngravingInfo();
    };

    return {
      carousel,
      isLoaded,
      mainImage,
      getFilteredGallery,
      isMobile,
      desktopZoom,
      zoomedOnDesktop,
      galleryWrapperClasses,
      goToEngravingInfo,
      openEngravingInfo
    };
  }
});
