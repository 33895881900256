





























































































































import { SfList } from '@storefront-ui/vue';
import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';
import {
  defineComponent,
  useContext,
  useRoute
} from '@nuxtjs/composition-api';
import { useConfig, useStore } from '~/composables';
import { useThemeUiState } from '~/diptyqueTheme/composable/useThemeUiState';

export default defineComponent({
  name: 'VaimoAvailableStores',
  components: {
    SfList,
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
  },
  props: {
    showBackButton: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const { changeToUrlRewrite: changeStore } = useStore();
    const route = useRoute();
    const {
      config: {
        value: { store_code }
      }
    } = useConfig();
    const { app: { $vsf, i18n: { locales } } = { i18n: { locales: [] } } } =
      useContext();
    const {
      app: { $gtm }
    } = useContext();
    const { closeMobileStoreSwitcher } = useThemeUiState();
    const continentsList = [
      {
        id: 'NA',
        label: 'North America'
      },
      {
        id: 'EU',
        label: 'Europe'
      },
      {
        id: 'AS',
        label: 'Asia'
      }
    ];

    const handleChangeStore = async (store) => {
      if ($vsf.$magento.config.state.getStore() === store.code && checkForAmericanStore(store)) {
        return;
      }
      if (['en_us', 'fr_us'].includes(store.code)) {
        lsSet('preferredAmericanStore', store.websiteName);
      }

      return changeStore(
        {
          store_code: store.code,
          default_display_currency_code: store.defaultCurrency
        },
        route.value.path
      );
    };

    const isWebsiteLocal = (store, index, localesArray) => {
      return (
        localesArray.findIndex((orgStore) => {
          return orgStore.websiteName === store.websiteName;
        }) === index
      );
    };

    const availableStoresByContinentId = (continentId) => {
      const getWebsiteNames = locales
        .filter(isWebsiteLocal)
        .map((store) => store.websiteName);

      return getWebsiteNames.map((websiteName) => {
        return {
          websiteName: websiteName,
          stores: locales.filter((store) => {
            return (
              store.websiteName === websiteName &&
              store.continent === continentId
            );
          })
        };
      });
    };

    const getClickChangeLanguageDetails = (store) => {
      $gtm.push({
        event: 'clickChangeLanguage',
        action: 'Language',
        country: store.websiteName
      })
    }

    const checkForAmericanStore = (store) => {
      return ['en_us', 'fr_us'].includes(store.code)
        ? store.websiteName === lsGet('preferredAmericanStore')
        : true
    }

    return {
      availableStoresByContinentId,
      currentStoreCode: store_code,
      handleChangeStore,
      closeMobileStoreSwitcher,
      continentsList,
      getClickChangeLanguageDetails,
      checkForAmericanStore
    };
  }
});
