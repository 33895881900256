



















import {
  SfSelect,
  SfLoader
} from '@storefront-ui/vue';
import { defineComponent, onBeforeMount } from '@nuxtjs/composition-api';
import { useXmas } from "~/diptyqueTheme/modules/xmas/composables/useXmas";
import { useConfig } from "~/composables";

export default defineComponent({
  name: 'XmasForm',
  components: {
    SfSelect,
    SfLoader
  },
  setup(props, { root }) {
    const { getMaxBagsCount, getChosenBagsCount, updateQty, chosenBagsCount, maxBagsCount } = useXmas();
    const { config } = useConfig();

    onBeforeMount(async () => {
      root.$on('reload-xmas-form', async () => {
        await getChosenBagsCount();
        await getMaxBagsCount();
      });

      await getChosenBagsCount();
      await getMaxBagsCount();
    });

    const updateBagsQty = async (event) => {
      await updateQty(event.target.value);
    }

    return {
      config,
      updateBagsQty,
      chosenBagsCount,
      maxBagsCount
    };
  },
});
