





































import {computed, defineComponent, PropType, useContext, useRouter} from '@nuxtjs/composition-api';
import { SfNotification } from '@storefront-ui/vue';

import AddToCartNotification from '@/diptyqueTheme/components/AddToCartNotification.vue';
import { useUiNotification } from '~/composables';
import {useCategoryStore} from "~/modules/catalog/category/stores/category";
interface AreaInterface {
  area: 'top' | 'default';
}
export default defineComponent({
  name: 'NotificationBar',
  components: {
    SfNotification,
    AddToCartNotification
  },
  props: {
    area: {
      required: false,
      type: String as PropType<AreaInterface['area']>,
      default: 'default' as AreaInterface['area']
    }
  },
  setup(props) {
    const {
      app: { $gtm },
      localePath
    } = useContext();
    const router = useRouter();
    const { notifications } = useUiNotification();
    const categoryStore = useCategoryStore();
    const getNotificationByArea = computed(() => {
      return notifications.value.filter((notification) => {
        if (!notification.area) {
          notification.area = 'default';
        }

        return notification.area === props.area;
      });
    });
    const getWrapperClasses = computed(() => {
      return [
        {
          active: getNotificationByArea.value.length > 0,
          'collection-page': categoryStore?.categoryType === 'collection'
        },
        'area-' + props.area
      ];
    });

    const close = (notification) => {
      $gtm.push({
        event: 'click_close_popin'
      });

      if (typeof notification === 'function') {
        notification();
      }
    };

    const onNotificationClick = (notification) => {
      if (notification.action_type === 'add_all_to_cart') {
        router.push(localePath('/cart'));
        return
      }
      return
    }

    return {
      notifications,
      onNotificationClick,
      getWrapperClasses,
      getNotificationByArea,
      close
    };
  }
});
