





































import {
  defineComponent,
  onMounted,
  useRouter,
  useContext
} from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useAccountStore } from '~/diptyqueTheme/stores/account';
import { useUiState } from '~/composables';
import VaimoHelpBlock from '~/diptyqueTheme/components/organisms/VaimoHelpBlock.vue';
import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import { focusTrap } from '@storefront-ui/vue/src/utilities/directives';
import { useStore } from '~/composables';

export default defineComponent({
  name: 'Main',
  components: {
    VaimoHelpBlock,
    VaimoButton
  },
  setup() {
    const accountStore = useAccountStore();
    const { isJpStore } = useStore();
    const { isCartSidebarOpen, toggleCartSidebar } = useUiState();
    const { logout, user: getUser } = useUser();
    const { app } = useContext();
    const router = useRouter();
    const navigation = [
      {
        title: app.i18n.t('My Account Information'),
        component: 'AccountInfo'
      },
      {
        title: app.i18n.t('My Orders'),
        component: 'AccountOrders'
      },
      {
        title: app.i18n.t('Address Book'),
        component: 'AccountShippingAddresses'
      },
      {
        title: app.i18n.t('My Contact Preferences'),
        component: 'AccountSubscriptions'
      }
    ];

    const hideSidebar = () => {
      if (accountStore.isVisible) {
        accountStore.isVisible = false;
      } else if (isCartSidebarOpen.value) {
        toggleCartSidebar(null);
      }
    };

    const navigateToPage = async (component) => {
      const navigationMap = {
        AccountInfo: 'customer-my-profile',
        AccountOrders: 'customer-order-history',
        AccountShippingAddresses: 'customer-addresses-details',
        AccountSubscriptions: 'customer-my-newsletter'
      };
      hideSidebar();
      await router.push(app.localeRoute({ name: navigationMap[component] }));
    };

    const logOutUser = async () => {
      await logout({});
      const homePath = app.localeRoute({ name: 'home' });
      await router.push(homePath);
      hideSidebar();
    };

    onMounted(() => {
      // Accessibility support: recreate focus area
      const sidebar = document.querySelector('aside.sf-sidebar__aside');
      if (sidebar) {
        focusTrap.componentUpdated(sidebar);
      }
    })

    return {
      navigation,
      logOutUser,
      navigateToPage,
      accountStore,
      getUser,
      isJpStore
    };
  }
});
