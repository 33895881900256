
























































































































import type { PropType } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  ref,
  useContext,
  watch
} from '@nuxtjs/composition-api';
import { SfInput, SfTextarea } from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';

import VaimoButton from '~/diptyqueTheme/components/atoms/VaimoButton.vue';
import VaimoHeading from '~/diptyqueTheme/components/atoms/VaimoHeading.vue';
import { useRules } from '~/diptyqueTheme/composable/useRules';
import { charsRegex, latinRegex } from '~/diptyqueTheme/helpers/regexes';

extend('latin', {
  message: 'Use only alphabetic letters',
  validate(value) {
    return {
      valid: latinRegex.test(value)
    };
  }
});

extend('chars', {
  message: 'Only these characters',
  validate(value) {
    return {
      valid: charsRegex.test(value)
    };
  }
});

type GiftMessageFormFields = {
  from: string;
  to: string;
  message: string;
};

export default defineComponent({
  name: 'VaimoGiftMessageForm',
  components: {
    SfInput,
    SfTextarea,
    ValidationObserver,
    ValidationProvider,
    VaimoHeading,
    VaimoButton
  },
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    maxLength: {
      type: Number,
      required: false,
      default: 200
    },
    form: {
      type: Object as PropType<GiftMessageFormFields>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['submit'],
  setup(props) {
    const {
      app: { i18n }
    } = useContext();
    const { getRules } = useRules();

    const formCopy = ref<GiftMessageFormFields>();
    watch(
      () => props.form,
      (newForm) => {
        formCopy.value = { ...newForm };
      },
      { immediate: true, deep: true }
    );

    const isFormExpanded = ref(false);

    const getRemainingCharacters = (message: string) => {
      const maxCharacters = props.maxLength || 0;
      return maxCharacters - message.length;
    };

    const remainingCharacters = computed(
      () =>
        i18n.t('Characters remaining: {0}', {
          0: getRemainingCharacters(formCopy.value?.message || '')
        }) as string
    );

    const isLoading = computed(() => props.loading);

    return {
      formCopy,
      getRules,
      isLoading,
      isFormExpanded,
      remainingCharacters
    };
  }
});
