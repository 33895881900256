var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"col-span-6 image-container",class:{
        'order-2': _vm.reverseContent,
        'lg:col-span-5': !_vm.isWideVisual,
        'lg:col-start-2': !_vm.isWideVisual && !_vm.reverseContent,
        'lg:col-start-7': !_vm.isWideVisual && _vm.reverseContent,
        'lg:col-span-8': _vm.isWideVisual,
        'lg:col-start-5': _vm.isWideVisual && _vm.reverseContent
      }},[(!_vm.isDesktop && _vm.data.heading)?_c('VaimoHeading',{staticClass:"module__heading mb-sm text-center",attrs:{"heading-level":"h3","heading":_vm.data.heading}}):_vm._e(),_vm._v(" "),_c('VaimoBanner',_vm._b({staticClass:"module__banner",attrs:{"max-optimized-width":708,"page-name":_vm.pageName,"section-name":_vm.data.heading}},'VaimoBanner',_vm.getBannerOrVisualData,false))],1),_vm._v(" "),_c('div',{staticClass:"content-container col-span-6 lg:col-span-4 text-center flex flex-col items-center justify-center",class:{
        'lg:order-1': _vm.reverseContent,
        'lg:col-start-8': !_vm.isWideVisual && !_vm.reverseContent,
        'lg:col-start-2': !_vm.isWideVisual && _vm.reverseContent,
        'lg:col-start-9': _vm.isWideVisual && !_vm.reverseContent,
        'wide-visual_left': _vm.isWideVisual && !_vm.reverseContent,
        'wide-visual_right': _vm.isWideVisual && _vm.reverseContent
      }},[(_vm.isDesktop && _vm.data.heading)?_c('VaimoHeading',{staticClass:"module__heading mb-2md lg:mb-md",attrs:{"heading-level":"h3","heading":_vm.data.heading}}):_vm._e(),_vm._v(" "),(_vm.data.subHeading)?_c('VaimoHeading',{staticClass:"module__subheading lg:mb-md uppercase",attrs:{"heading":_vm.data.subHeading,"heading-level":"h4","heading-link":_vm.data.headingLink}}):_vm._e(),_vm._v(" "),(_vm.data.descr)?_c('VaimoTruncatedText',{staticClass:"module__descr",attrs:{"text":_vm.data.descr,"truncate":"by characters","characters":145,"animated-text":""}}):_vm._e(),_vm._v(" "),(_vm.productPrice)?_c('p',{staticClass:"module__price font-normal mt-sm"},[_vm._v("\n        "+_vm._s(_vm.productPrice)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.data.label)?_c('VaimoBadge',{staticClass:"module__label mt-sm"},[_vm._v(_vm._s(_vm.data.label))]):_vm._e(),_vm._v(" "),(_vm.buttons.length)?_c('VaimoButtons',{staticClass:"module__buttons mt-md lg:mt-2md text-center",attrs:{"section-data":{ buttonCollection: { items: _vm.buttons } }}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }