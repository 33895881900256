






















































import {
  defineComponent,
  useContext,
  useRouter,
  useRoute,
  onBeforeMount,
  computed
} from '@nuxtjs/composition-api';
import { useSidebarLinkGroups } from './useSidebarLinkGroups';
import { useUser } from '../../composables/useUser';
import { useScreenSize } from '~/diptyqueTheme/composable/useScreenSize';
import {MetaInfo} from "vue-meta";

export default defineComponent({
  name: 'MyAccount',
  components: {
    VaimoHelpBlock: () => import('organisms/VaimoHelpBlock.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue')
  },
  head(): MetaInfo {
    const pageTitle = this.$i18n.t(this.$route?.meta?.titleLabel ?? '');
    return {
      title: pageTitle as string
    };
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { app, i18n, localeRoute } = useContext();
    const { user: getUser, logout } = useUser();
    const { isMobile } = useScreenSize();

    const getCurrentRouteName = computed(() => route.value.name);

    const { sidebarLinkGroups } = useSidebarLinkGroups();

    const isOnSubpage = computed(() => route.value.matched.length > 1);
    const goToTopLevelRoute = () =>
      router.push(localeRoute({ name: 'customer' }));
    const title = computed(() =>
      i18n.t(route.value.matched.at(-1)?.meta.titleLabel as string)
    );



    const goToPage = (page) => {
      router.push(page);
    };

    const logOutUser = async () => {
      await logout({});

      const homePath = app.localeRoute({ name: 'home' });
      goToPage(homePath);
    };

    const visibleJPOnly = (link) => {
      if (link.name === 'customer-vault-card-list') {
        return !(app.i18n.localeProperties.code == 'ja_jp');
      } else {
        return false;
      }
    };

    onBeforeMount(() => {
      if (!app.$vsf.$magento.config.state.getCustomerToken()) {
        const homePath = app.localeRoute({ name: 'home' });
        goToPage(homePath);
      }
    })

    return {
      sidebarLinkGroups,
      title,
      isOnSubpage,
      logOutUser,
      getCurrentRouteName,
      goToPage,
      getUser,
      goToTopLevelRoute,
      isMobile,
      visibleJPOnly
    };
  }
});
