













































































































































































































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  reactive,
  ref,
  watch,
  onMounted,
  useContext
} from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { SfInput, SfSelect } from '@storefront-ui/vue';
import {max, min, required} from 'vee-validate/dist/rules';
import { useCountriesStore } from '~/diptyqueTheme/stores/countries';
import { doubleQuotesRegex,nameRegex, charsRegex, phoneRegex, katakanaRegex, hiraganaRegex, kanaRegex, phoneRegexJP, postcodeJP } from '~/diptyqueTheme/helpers/regexes';
import { useStore, useConfig } from '~/composables';
import { useZip2Address } from '~/diptyqueTheme/composable/useZip2Address';
import { usePhoneMask } from '~/diptyqueTheme/composable/usePhoneMask';
import { usePostcode } from '~/diptyqueTheme/composable/usePostcode';
import { useRules } from '~/diptyqueTheme/composable/useRules';
import { useCustomTabFocus } from '~/diptyqueTheme/composable/useCustomTabFocus';
import VaimoPhone from 'organisms/VaimoPhone.vue';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('alpha', {
  validate(value) {
    return {
      valid: /^[a-zA-ZÀ-Ÿ0-9\s., &#/!\'-]+$/.test(value)
    };
  },
  message: 'Use only alphabetic letters'
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters'
});

extend('max', {
  ...max,
  message: '30 characters maximum'
});

extend('chars', {
  message: 'Only these characters',
  validate(value) {
    return {
      valid: charsRegex.test(value)
    };
  }
});

extend('name', {
  message: 'Use only alphabetic letters',
  validate(value) {
    return {
      valid: nameRegex.test(value)
    };
  }
});

extend('validate-katakana', {
  message: 'Please enter in the full-width katakana.',
  validate(value) {
    return {
      valid: katakanaRegex.test(value)
    };
  }
});

extend('validate-hiragana', {
  message: 'Please use Hiragana only in this field.',
  validate(value) {
    return {
      valid: hiraganaRegex.test(value)
    };
  }
});

extend('validate-kana', {
  message: 'Please use full width kana only in this field.',
  validate(value) {
    return {
      valid: kanaRegex.test(value)
    };
  }
});

extend('phone', {
  validate(value) {
    return {
      valid: phoneRegex.test(value.replace(/\D/g, ''))
    };
  }
});

extend('doubleQuotes', {
  message: '" is a forbidden character',
  validate(value) {
    return {
      valid: doubleQuotesRegex.test(value)
    };
  }
});

extend('phone-jp', {
  message: 'Please enter only half-width numbers without hyphens and up to 11 digits.',
  validate(value) {
    return {
      valid: phoneRegexJP.test(value.replace(/ /g, ''))
    };
  }
});

extend('postcode-jp', {
  message: 'Provided Zip/Postal Code seems to be invalid.',
  validate(value) {
    return {
      valid: postcodeJP.test(value)
    };
  }
});

export default defineComponent({
  name: 'VaimoAddEditShippingAddress',
  components: {
    SfInput,
    SfSelect,
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoIcon: () => import('atoms/VaimoIcon.vue'),
    ValidationProvider,
    VaimoPhone,
    ValidationObserver
  },
  props: {
    type: {
      required: false,
      type: String,
      default: 'create'
    },
    address: {
      required: false,
      type: Object,
      default: null
    },
    loading: {
      required: true,
      type: Boolean
    },
    addressType: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: ['cancel', 'save'],
  setup(props, { emit }) {
    const activeAddress = computed(() => props.address);
    const activeAddressId = computed(() => activeAddress.value?.id);
    const activeFormType = computed(() => props.type);
    const loadingForm = computed(() => props.loading);
    const countries = ref([]);
    const { clearMask } = usePhoneMask();
    const customerStore = useCustomerStore();
    const { getRules } = useRules();
    const { defaultPostcodes, isHkMoCode } = usePostcode();
    const { isJpStore, getNameKanaRules } = useStore();
    const { searchZipCode, zip2AddressData } = useZip2Address();
    const { applyCustomTabFocus } = useCustomTabFocus();
    const { config } = useConfig();
    const isPhoneValid = ref(true);
    const { app } = useContext();

    const {
      allowedCountries,
      loadCountriesList,
      countries: allCountries
    } = useCountriesStore();
    const appliedRules = computed(() => {
      if (isJpStore.value) {
        return 'required|postcode-jp';
      }
      switch (props.addressType) {
        case 'shipping':
          return getRules({
            default: 'required|alpha|min:2',
            en_hk: 'chars|doubleQuotes',
            zh_hk: 'chars|doubleQuotes',
            ja_jp: 'chars'
          });
        case 'billing':
          return getRules({
            default: 'required|alpha',
            en_hk: 'chars|doubleQuotes',
            zh_hk: 'chars|doubleQuotes',
            ja_jp: 'chars'
          });
        default:
          return getRules({
            default: 'required|alpha',
            en_hk: 'chars|doubleQuotes',
            zh_hk: 'chars|doubleQuotes',
            ja_jp: 'chars'
          });
      }
    });

    const isDeStore = computed(
      () => app.i18n.localeProperties.code === 'de_eu'
    );

    const nameKanaRules = getNameKanaRules();

    const zip2Address = async () => {
      if (isJpStore.value && postcodeJP.test(form.postcode)) {
        await searchZipCode(form.postcode);
        if (Object.keys(zip2AddressData.value).length) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore;
          form.region = regionList.value.find(item => item.region.includes(zip2AddressData.value.region));
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore;
          form.city = zip2AddressData.value.city;
        }
      }
    };

    const formMap = {
      city: null,
      country_code: {
        code: null,
        name: null,
        regions: []
      },
      default_billing: false,
      default_shipping: false,
      address_book_type: '',
      company: null,
      firstname: null,
      lastname: null,
      firstnamekana: null,
      lastnamekana: null,
      postcode: '',
      prefix: null,
      region: {
        region: null,
        region_code: null,
        region_id: null
      },
      street: [null],
      suffix: null,
      telephone: '',
      vat_id: null
    };

    const form = reactive({ ...formMap });
    const phoneKey = ref(props.addressType);

    const isStateFieldVisible = computed(() => {
      return (
        form.country_code?.code &&
        (form.country_code?.code === 'US' || form.country_code?.code === 'CA' || form.country_code?.code === 'JP')
      );
    });

    const isPostcodeFieldVisible = computed(() => {
      return !isHkMoCode(form.country_code?.code);
    });

    const regionList = ref([]);
    const onCountryChange = (countryCode) => {
      if (countries.value.length > 0) {
        const foundCounty = countries.value.find(
          (country) => country?.code === countryCode
        );
        if (foundCounty) {
          form.country_code = foundCounty;

          if (foundCounty.regions?.length) {
            form.region = {
              region: null,
              region_code: null,
              region_id: null
            };
            regionList.value = foundCounty.regions.reduce((memo, cur) => {
              memo.push({
                region_code: cur.code,
                region: cur.name,
                region_id: cur.id
              });
              return memo;
            }, []);
          }
        }
      }
    };

    const updateRegion = (region) => {
      if (regionList.value.length > 0) {
        const foundState = regionList.value.find(
          (state) => state?.region_code === region
        );

        if (foundState) form.region = foundState;
      }
    };

    const getCountryObject = (code) => {
      return countries.value?.find((country) => country.code === code);
    };

    const transformAddressType = (type) => {
      const typesMap = {
        billing: 1,
        shipping: 2
      };
      return typesMap[type];
    };

    const fillTheForm = (address) => {
      if (!address) address = formMap;
      const addr = address ? JSON.parse(JSON.stringify(address)) : [];
      if (isJpStore.value && !addr?.country_code?.code) {
        addr.country_code = 'JP';
      }
      for (const addressKey in addr) {
        if (
          addressKey === 'country_code' &&
          typeof addr[addressKey] === 'string'
        ) {
          form.country_code.code = availableCountryCode(addr[addressKey]);
          form.country_code.name = null;
          form.country_code.regions = [];
        } else {
          form[addressKey] = addr[addressKey];
        }
      }
      if (isJpStore.value && !form.country_code.code) {
        form.country_code.code = 'JP';
      }
      if (address?.id) {
        phoneKey.value = address.id;
      }
    };

    const availableCountryCode = (valueToCheck) => {
      if (
        Array.isArray(countries.value) &&
        countries.value.length > 0 &&
        valueToCheck !== undefined &&
        valueToCheck !== null &&
        countries.value.some((country) => country?.code === valueToCheck)
      ) {
        return valueToCheck;
      }

      return '';
    };

    onBeforeMount(async () => {
      /*
       * If addressType is billing we should use allCountries instead of allowed countries for specific store
       * */
      if (props.addressType === 'billing') {
        await loadCountriesList();
        countries.value = allCountries.map((country) => ({
          code: country.id,
          name: country.full_name_locale,
          regions: country.available_regions
        }));
      } else {
        countries.value = allowedCountries.map((country) => ({
          code: country.id,
          name: country.label,
          regions: country.availableRegions
        }));
      }

      fillTheForm(activeAddress.value);

      const contryObj = getCountryObject(form.country_code?.code);
      if (contryObj) form.country_code = contryObj;
      regionList.value =
        countries.value?.find(
          (country) => country?.code === form.country_code?.code
        )?.regions || [];

      if (regionList.value?.length) {
        regionList.value = regionList.value.reduce((memo, cur) => {
          memo.push({
            region_code: cur.code,
            region: cur.name,
            region_id: cur.id
          });
          return memo;
        }, []);
      }

      if (props.type !== 'edit') {
        form.address_book_type = transformAddressType(props.addressType);
      }
    });

    watch(activeAddressId, () => {
      fillTheForm(activeAddress.value);
    });

    const isDefaultShippingAddress = computed(() => {
      return (
        activeFormType.value === 'edit' && activeAddress.value?.default_shipping
      );
    });

    const isDefaultBillingAddress = computed(() => {
      return (
        activeFormType.value === 'edit' && activeAddress.value?.default_billing
      );
    });

    const checkAddresses = (type) => {
      return (
        !customerStore.user?.addresses?.filter(
          //@ts-ignore
          (address) => address.address_book_type === type
        )?.length && props.addressType === type
      );
    };

    const checkDefaultAddress = (type) => {
      const currentAddressType = customerStore.user?.addresses?.filter(
        //@ts-ignore
        (address) => address.address_book_type === type
      );
      return (
        !currentAddressType?.some((address) => address[`default_${type}`]) &&
        props.addressType === type
      );
    };

    const saveForm = async () => {
      if (!isPhoneValid.value) {
        return;
      }
      if (checkAddresses('shipping')) {
        form.default_shipping = true;
      } else if (checkAddresses('billing')) {
        form.default_billing = true;
      }

      if (checkDefaultAddress('shipping')) {
        form.default_shipping = true;
      } else if (checkDefaultAddress('billing')) {
        form.default_billing = true;
      }

      if (defaultPostcodes[form.country_code?.code]) {
        form.postcode = defaultPostcodes[form.country_code?.code];
      }

      form.country_code = getCountryObject(form.country_code?.code);
      form.address_book_type = transformAddressType(form.address_book_type);
      emit('save', {
        action: activeFormType.value,
        form: {
          ...form,
          country_code: form.country_code?.code
        }
      });
    };

    const cancelForm = () => {
      emit('cancel');
    };

    // Apply custom TAB focus order for JP store to fix the shuffled focus order
    // as JP form has changed shipping fields order with CSS "order" styles
    const applyCustomTabFocusForJP = () => {
      if (isJpStore.value) {
        // Shipping form
        if (props.addressType === 'shipping') {
          applyCustomTabFocus(
            [
              { from: '#firstnamekana', to: '#post_code' },
              { from: '#post_code', to: '.state select' },
              { from: '.state select', to: '#city' },
              { from: '#city', to: '#address' },
              { from: '#address', to: '#additional_address' },
              { from: '#additional_address', to: '.phone input' }
            ],
            '.shipping-form.add-edit-shipping-address'
          );
        }
        // Billing form
        if (props.addressType === 'billing') {
          applyCustomTabFocus(
            [
              { from: '#firstnamekana', to: '#post_code', toFallback: '.state select' },
              { from: '#post_code', to: '.state select' },
              { from: '.state select', to: '#city' },
              { from: '#city', to: '#address' },
              { from: '#address', to: '#additional_address' },
              { from: '#additional_address', to: '.phone input' }
            ],
            '.billing-form.add-edit-shipping-address'
          );
        }
      }
    };

    const phoneValidHandler = (value) => {
      isPhoneValid.value = value;
    };

    onMounted(() => {
      applyCustomTabFocusForJP();
    });

    return {
      form,
      getRules,
      loadingForm,
      isDeStore,
      saveForm,
      countries,
      cancelForm,
      isDefaultShippingAddress,
      isDefaultBillingAddress,
      appliedRules,
      isStateFieldVisible,
      onCountryChange,
      regionList,
      updateRegion,
      isPostcodeFieldVisible,
      isJpStore,
      zip2Address,
      nameKanaRules,
      config,
      isPhoneValid,
      phoneValidHandler,
      phoneKey
    };
  }
});
