<template>
  <div
    class="hero-header container m-and-l:px-md grid grid-cols-16"
    :class="{ 'mb-3md': !getBannerData.visible }"
  >
    <div
      v-if="getBannerData.visible"
      class="col-banner"
      :class="colClasses.visual"
    >
      <VaimoBanner
        v-bind="getBannerData"
        :ratio="ratio"
        class="module__banner"
        :max-optimized-width="1436"
        loading="eager"
        :page-name="pageName"
        :section-name="sectionData.heading"
        :preload="true"
        :lcp="true"
      />
    </div>
    <div class="col-content px-section" :class="colClasses.content">
      <div class="vertical-centered__content">
        <VaimoHeading
          v-if="sectionData.heading"
          :heading="sectionData.heading"
          :heading-link="sectionData.headingLink"
          heading-level="h1"
          class="module__heading mb-md"
        />
        <VaimoTruncatedText
          v-if="sectionData.descr"
          :text="sectionData.descr"
          class="module_descr paragraph-3"
          :class="descrFontClass"
        />
        <VaimoButton
          v-if="getButtonData.visible"
          v-bind="getButtonData"
          :aria-label="getButtonData.text"
          tabindex="0"
          class="module__button mt-md"
          >{{ getButtonData.text }}</VaimoButton
        >

        <!-- TODO: This item has to be removed from template as soon  -->
        <!-- as DPTQ-3293 will be deployed to prod -->
        <!-- and as soon as HeroHeader prod entries content will be refactored for Button sub-entries -->
        <VaimoButton
          v-if="getCTAdata.buttonVisibility && !getButtonData.visible"
          v-bind="getCTAdata"
          :aria-label="sectionData.ctaText"
          tabindex="0"
          class="module__cta mt-md"
          >{{ sectionData.ctaText }}</VaimoButton
        >
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, useContext } from '@nuxtjs/composition-api';

import { useDeclination } from '@/diptyqueTheme/composable/useDeclination';

export default defineComponent({
  name: 'HeroHeader',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButton: () => import('atoms/VaimoButton.vue'),
    VaimoHeading: () => import('atoms/VaimoHeading.vue'),
    VaimoTruncatedText: () => import('atoms/VaimoTruncatedText.vue')
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    },
    pageName: {
      type: String,
      required: false,
      default: ''
    },
    pageType: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props) {
    const { getCTAdata, getButtonData, getBannerData, isDesktop } =
      useDeclination(props.sectionData);
    const { app } = useContext();
    const { isDesktop: isScreenDesktop } = app.$device;

    const ratio = computed(() => {
      if (isScreenDesktop) {
        return props.sectionData?.ratioD || '16:9';
      }

      return props.sectionData.ratio || '5:6';
    });

    const colClasses = computed(() => {
      const classes = { visual: [], content: [] };
      const hasVisual = getBannerData.value?.visible;
      const visPosition = props.sectionData?.visualPosD || 'Left';

      if (hasVisual) {
        if (visPosition !== 'Top' && isDesktop.value) {
          classes.content.push(
            ratio.value === '1:1' ? 'col-span-9' : 'col-span-6'
          );
          classes.visual.push(
            ratio.value === '1:1' ? 'col-span-7' : 'col-span-10'
          );
          classes.content.push('vertical-centered');
          if (visPosition === 'Right') classes.visual.push('order-2');
        } else {
          classes.visual.push('col-span-16 mb-3md');
          classes.content.push('col-span-16 text-center');
        }
      } else {
        classes.content.push('col-span-16 text-center no-visual');
      }

      return classes;
    });

    const descrFontClass = computed(() => {
      const font =
        props.sectionData?.descrFont?.split(' - ')[0].toLowerCase() ||
        'primary';
      return 'ff-' + font;
    });

    return {
      ratio,
      colClasses,
      getBannerData,
      getButtonData,
      getCTAdata,
      descrFontClass
    };
  }
});
</script>

<style scoped lang="scss">
.hero-header {
  &.grid-cols-16,
  .grid-cols-16 {
    grid-template-columns: repeat(16, minmax(0, 1fr));

    .px-section {
      @include for-screen-m-and-l {
        padding-left: 52.5px;
        padding-right: 52.5px;
      }
    }

    .px-section {
      &.no-visual {
        max-width: 610px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .col-span-16 {
    grid-column: span 16 / span 16;
  }

  .no-visual {
    padding-top: 30px;
  }
}
</style>
