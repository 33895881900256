<template>
  <div class="vaimo-truncated-text" :class="{ animate: animatedText }">
    <template v-if="doubleText && renderedTextLong.length">
      <div
        ref="textElem"
        :class="textClasses"
        :style="textStyles"
        v-html="$dompurify(renderedText)"
      />
      <div
        v-if="!isTruncated"
        :style="textStyles"
        v-html="$dompurify(renderedTextLong)"
      />
      <a
        href="#readmoreLong"
        class="vaimo-truncated-text__readmore text-gris cursor-pointer font-normal"
        :aria-label="isTruncated ? $t('Read more') : $t('Read less')"
        @click.prevent="readMoreLong()"
      >
        {{ isTruncated ? $t('Read more') : $t('Read less') }}
      </a>
    </template>

    <template v-else>
      <template v-if="truncate === 'by lines'">
        <div
          ref="textElem"
          :class="textClasses"
          :style="textStyles"
          v-html="$dompurify(renderedText)"
        />
        <a
          v-if="isReadMoreVisible && truncatedText"
          href="#readmore"
          class="vaimo-truncated-text__readmore text-gris cursor-pointer font-normal"
          :aria-label="isTruncated ? $t('Read more') : $t('Read less')"
          @click.prevent="readMore()"
        >
          {{ isTruncated ? $t('Read more') : $t('Read less') }}
        </a>
      </template>

      <template v-else>
        <span
          class="vaimo-truncated-text__text"
          :class="textClasses"
          :style="textStyles"
          v-html="$dompurify(renderedText)"
        />
        <a
          v-if="truncatedText"
          href="#readmore"
          class="vaimo-truncated-text__readmore text-gris cursor-pointer font-normal"
          :aria-label="isTruncated ? $t('Read more') : $t('Read less')"
          @click.prevent="readMore()"
        >
          {{ isTruncated ? $t('Read more') : $t('Read less') }}
        </a>
      </template>
    </template>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  toRefs
} from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'VaimoText',
  props: {
    text: {
      type: String,
      required: true
    },
    textLong: {
      type: String,
      required: false
    },
    truncate: {
      type: String,
      default: 'none',
      validator: (value) =>
        ['none', 'by characters', 'by lines', 'by teaser'].includes(
          value.toLowerCase()
        )
    },
    characters: {
      type: Number,
      default: null
    },
    lines: {
      type: Number,
      default: null
    },
    teaser: {
      type: String,
      default: null
    },
    markdown: {
      type: Boolean,
      default: false
    },
    animatedText: {
      type: Boolean,
      default: false
    },
    doubleText: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    const $markdown = root?.$markdown;
    const { text, textLong, truncate, characters, lines, teaser } =
      toRefs(props);
    const isTruncated = ref(truncate.value !== 'none');

    const truncatedText = computed(() => {
      if (truncate.value === 'by characters') {
        if (text.value.length <= characters.value) {
          return null;
        }
        let trimmedString = text.value.substring(0, characters.value);
        trimmedString = trimmedString.substring(
          0,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
        );
        return trimmedString + '...';
      }
      if (truncate.value === 'by lines') return text.value;
      if (truncate.value === 'by teaser') return teaser.value + '...';
      return null;
    });

    const renderedText = computed(() => {
      let renderedText = '';
      if (isTruncated.value) {
        renderedText = truncatedText.value || text.value;
      } else {
        renderedText = text.value;
      }
      if (props.markdown) {
        renderedText = $markdown.render(renderedText);
      }
      if (props.animatedText) {
        renderedText = renderedText
          .split(/\s+/)
          .map((word) => {
            return `<span class="animate-description">${word}</span> `;
          })
          .join('');
      }
      return renderedText;
    });

    const renderedTextLong = computed(() => {
      let renderedTextLong = textLong.value;
      if (props.markdown) {
        renderedTextLong = $markdown.render(renderedTextLong);
      }
      if (props.animatedText) {
        renderedTextLong = renderedTextLong
          .split(/\s+/)
          .map((word) => {
            return `<span class="animate-description">${word}</span> `;
          })
          .join('');
      }
      return renderedTextLong;
    });

    const textClasses = computed(() => {
      const list = ['truncated-' + truncate.value.replace(' ', '-')];
      if (isTruncated.value) list.push('is-truncated');

      return list;
    });

    const textStyles = computed(() => {
      if (truncate.value === 'by lines') {
        return { '-webkit-line-clamp': lines.value };
      }

      return null;
    });

    const readMore = () => {
      isTruncated.value = !isTruncated.value;
    };

    const readMoreLong = () => {
      isTruncated.value = !isTruncated.value;
    };

    const textElem = ref();
    const textElemScrollHeight = computed(
      () => textElem.value?.scrollHeight - 10
    );
    const textElemClientHeight = computed(() => textElem.value?.clientHeight);
    const isReadMoreVisible = computed(() => {
      return textElemScrollHeight.value > textElemClientHeight.value;
    });

    return {
      isTruncated,
      renderedText,
      renderedTextLong,
      textClasses,
      textStyles,
      readMore,
      readMoreLong,
      textElem,
      isReadMoreVisible,
      textElemScrollHeight,
      textElemClientHeight,
      truncatedText
    };
  }
});
</script>

<style lang="scss" scoped>
.vaimo-truncated-text {
  position: relative;

  &__text {
    white-space: pre-wrap;
  }

  &__readmore {
    white-space: nowrap;
  }

  .truncated-by-lines {
    &.is-truncated {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      &::v-deep p {
        display: inline;
      }

      & + .vaimo-truncated-text__readmore {
        position: absolute;
        padding-right: inherit;
        display: inline-block;
        background: var(--c-white);
        right: 0;
        bottom: 1px;

        &:before {
          content: '...';
          display: inline-block;
          position: absolute;
          width: 15px;
          bottom: -2px;
          left: -15px;
          color: var(--c-noir);
          background: var(--c-white);
        }
      }
    }
  }

  .vaimo-truncated-text__readmore {
    text-decoration: underline;
  }

  ::v-deep {
    h6 {
      @apply paragraph-5;
    }
  }
}
</style>
