import { render, staticRenderFns } from "./VaimoGiftMessageForm.vue?vue&type=template&id=1260617a&scoped=true&"
import script from "./VaimoGiftMessageForm.vue?vue&type=script&lang=ts&"
export * from "./VaimoGiftMessageForm.vue?vue&type=script&lang=ts&"
import style0 from "./VaimoGiftMessageForm.vue?vue&type=style&index=0&id=1260617a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1260617a",
  null
  
)

export default component.exports