<template>
  <div class="product-banner2 px-section">
    <div class="product-banner2__container max-w-[610px] mx-auto s:px-md">
      <div v-if="product" class="product-banner2__grid grid gap-2md m-and-l:gap-3md">
        <div class="product-banner2__visual col-span-1">
          <VaimoBanner
            v-if="product.thumbnail"
            :src="product.thumbnail.url"
            :link="productLink"
            source="magento"
            ratio="3:4"
            :max-optimized-width="500"
            class="module__banner"
          />
        </div>
        <div class="product-banner2__content col-span-2 flex items-center">
          <div class="product-banner2__content-wrapper">
            <div v-if="title" class="title"><SfLink :link="productLink" class="reset-styles">{{ title }}</SfLink></div>
            <div v-if="subtitle" class="paragraph-4 mt-sm text-gris_perle">{{ subtitle }}</div>
            <div
              v-if="plpDescription"
              class="paragraph-4 mt-sm text-gris_perle"
            >
              {{ plpDescription }}
            </div>
            <div v-if="variantName" class="model mt-md m-and-l:mt-3md">
              {{ variantName }}
            </div>
            <div class="price price-text mt-md m-and-l:mt-3md">
              {{ customProductGetters.getProductCardPrice(product) }}
            </div>
          </div>
        </div>
      </div>

      <VaimoButtons
        :section-data="sectionData"
        :block="true"
        class="module__buttons text-center mt-3md"
      />

    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  useFetch
} from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

import { useProduct } from '~/composables';
import { useDeclination } from '~/diptyqueTheme/composable/useDeclination';
import customProductGetters from '~/diptyqueTheme/helpers/productGetters';

export default defineComponent({
  name: 'ProductBanner2',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue'),
    VaimoButtons: () => import('molecules/VaimoButtons.vue'),
    SfLink
  },
  props: {
    sectionData: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const { getProductList, loading: productsLoading } = useProduct();

    const product = ref({});
    const ctfProductItem = computed(() => {
      const products = props.sectionData?.itemsCollection?.items?.filter(
        (item) => item?.__typename === 'ProductItem'
      );
      return products.length ? products[0] : null;
    });

    const getProduct = async () => {
      if (!ctfProductItem.value?.sku) return false;

      try {
        const data = await getProductList({
          filter: {
            ['sku']: {
              in: [ctfProductItem.value.sku]
            }
          },
          pageSize: 1
        });
        product.value = data?.items?.[0] || {};
      } catch (error) {
        console.error('ProductBanner2 getProduct()', error);
      }
    };

    useFetch(async () => {
      await getProduct();
    });

    const { getBannerData } = useDeclination(props.sectionData);
    const bannerSrc = computed(() => getBannerData.value.src);

    const productLink = computed(() => {
      const link = product.value?.url_rewrites?.[0]?.url;
      return link ? '/' + link : '';
    });

    const title = computed(() => {
      if (ctfProductItem.value?.visibility?.includes('Name 2')) {
        return product.value?.name;
      }
      return product.value?.identity_name || product.value?.name;
    });

    const plpDescription = computed(() => {
      if (ctfProductItem.value?.visibility?.includes('PLP Description')) {
        return product.value?.plp_description || '';
      }
      return '';
    });

    const variantName = computed(() => {
      if (ctfProductItem.value?.visibility?.includes('Variant Name')) {
        return product.value?.variant_name?.[0]?.label || '';
      }
      return '';
    });

    const subtitle = computed(() => {
      if (ctfProductItem.value?.visibility?.includes('Subtitle')) {
        return product.value?.subtitle || '';
      }
      return '';
    });

    return {
      getBannerData,
      customProductGetters,
      bannerSrc,
      ctfProductItem,
      product,
      productLink,
      title,
      plpDescription,
      variantName,
      subtitle
    };
  }
});
</script>

<style scoped lang="scss">
.product-banner2 {
  &__grid {
    grid-template-columns: 98px 1fr 1fr !important;
    @include for-screen-m-and-l {
      grid-template-columns: 190px 1fr 1fr !important;
    }
  }
  &__content {
    .title {
      font-size: 18px;
      font-weight: 400;
      line-height: 24.3px;
      font-family: var(--font-family--diptyque);
      @include for-screen-s {
        font-size: var(--font-size--lg);
        font-weight: 400;
      }
    }
    .model {
      font-size: var(--font-size--sm);
      font-weight: 400;
      line-height: 16.2px;
      @include for-screen-s {
        font-size: var(--font-size--xs);
      }
    }
    .price {
      @include for-screen-s {
        font-size: var(--font-size--lg);
      }
    }
  }
}
</style>
