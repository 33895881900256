import { render, staticRenderFns } from "./CategoryCollectionSidebar.vue?vue&type=template&id=4c1b74a8&scoped=true&"
import script from "./CategoryCollectionSidebar.vue?vue&type=script&lang=ts&"
export * from "./CategoryCollectionSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryCollectionSidebar.vue?vue&type=style&index=0&id=4c1b74a8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1b74a8",
  null
  
)

export default component.exports